<template>
  <div class="min-h-screen">
    <div class="py-4">
      <h3 class="text-lg font-medium text-gray-900 dark:text-white text-left">Version History</h3>
      <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
        Track changes in the configuration of different tables across your organization.
        <br />
        Due to bundling of changes, this page may be 1 minute behind the actual changes.
      </p>

      <div class="flex items-center justify-between gap-4 flex-wrap mt-5">
        <Search :model-value="search" placeholder="Search..." @update:model-value="search = $event" @input="page = 1" />

        <div class="flex gap-4 items-center">
          <div class="relative">
            <button class="gray-btn" @click="showFilesDropdown = !showFilesDropdown">
              <Icon icon="mdi:file-document-outline" class="w-4 h-4 text-gray-500 dark:text-gray-400 shrink-0" />
              <span class="max-w-60 truncate">
                {{
                  selectedFiles.length
                    ? selectedFiles.length === allFiles.length
                      ? 'All Files'
                      : selectedFiles.length === 1
                        ? allFiles.find(file => file.value === selectedFiles[0]).label
                        : `${selectedFiles.length} Files Selected`
                    : 'All Files'
                }}
              </span>
            </button>
            <div
              v-if="showFilesDropdown"
              v-click-away="() => (showFilesDropdown = false)"
              class="absolute z-20 bg-white rounded-xl shadow w-max min-w-60 dark:bg-gray-800 right-0 top-[calc(100%+10px)]"
            >
              <div class="p-1 pb-0">
                <Search
                  :model-value="search_files"
                  placeholder="Search.."
                  class="!p-2"
                  @update:model-value="search_files = $event"
                />
              </div>
              <ul class="h-48 p-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200">
                <li v-for="file in allFiles" :key="file.value">
                  <div class="flex items-center pl-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700">
                    <input
                      :id="file.value"
                      type="checkbox"
                      :checked="selectedFiles.includes(file.value)"
                      class="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      @change="toggleFile(file.value)"
                    />
                    <label
                      :for="file.value"
                      class="w-max p-1 cursor-pointer ms-2 text-xs font-medium text-gray-900 rounded dark:text-gray-300"
                    >
                      {{ file.label }} {{ `(${file.count})` }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="relative">
            <button class="gray-btn" @click="showUsersDropdown = !showUsersDropdown">
              <Icon icon="ph:users" class="w-4 h-4" />
              <span class="max-w-60 truncate">
                {{
                  selectedUsers.length
                    ? selectedUsers.length === allUsers.length
                      ? 'All Users'
                      : selectedUsers.length === 1
                        ? allUsers.find(user => user.value === selectedUsers[0]).label
                        : `${selectedUsers.length} Users Selected`
                    : 'All Users'
                }}
              </span>
            </button>
            <div
              v-if="showUsersDropdown"
              v-click-away="() => (showUsersDropdown = false)"
              class="absolute z-20 bg-white rounded-xl shadow min-w-60 dark:bg-gray-800 right-0 top-[calc(100%+10px)]"
            >
              <div class="p-1 pb-0">
                <Search
                  :model-value="search_users"
                  placeholder="Search.."
                  class="!p-2"
                  @update:model-value="search_users = $event"
                />
              </div>
              <ul class="h-48 p-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200">
                <li v-for="user in allUsers" :key="user.value">
                  <div class="flex items-center pl-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700">
                    <input
                      :id="user.value"
                      type="checkbox"
                      :checked="selectedUsers.includes(user.value)"
                      class="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      @change="toggleUser(user.value)"
                    />
                    <label
                      :for="user.value"
                      class="w-max p-1 cursor-pointer ms-2 text-xs font-medium text-gray-900 rounded dark:text-gray-300"
                    >
                      {{ user.label }} {{ `(${user.count})` }}
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading" class="w-full border primary-border rounded-6 mt-4 flex flex-col gap-[2px]">
        <div
          v-for="item in [1, 2, 3, 4, 5, 6, 8]"
          :key="item"
          class="px-4 py-3 flex items-center justify-between border-b primary-border dark:bg-gray-ds-400 h-[70px] animate-pulse"
        >
          <div class="flex flex-col gap-1 w-full">
            <div class="w-[80%] max-w-[220px] h-4 bg-gray-200 dark:bg-gray-600 rounded animate-pulse"></div>
            <div class="w-[90%] max-w-[280px] h-3 bg-gray-200 dark:bg-gray-600 rounded animate-pulse"></div>
          </div>
          <div class="flex items-center gap-2 w-max shrink-0">
            <div class="w-[20px] h-4 bg-gray-200 dark:bg-gray-600 rounded"></div>
            <div class="w-[20px] h-4 bg-gray-200 dark:bg-gray-600 rounded"></div>
          </div>
        </div>
      </div>
      <div v-else class="w-full border primary-border rounded-6 mt-4">
        <Commit
          v-for="(commit, index) in commitToDisplay"
          :key="commit.id"
          :commit="commit"
          :class="{ 'border-b primary-border': index !== commits.length - 1 }"
          @open-commit-diff="openCommitDiff"
          @copy-commit-link="handleCopyCommitLink"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed } from 'vue'
  import Commit from '@/components/Commit.vue'
  import axios from '@/axiosInstance'
  import { formatDistanceToNow, parse } from 'date-fns'

  // states
  const search = ref('')
  const page = ref(1)
  const commits = ref()
  const loading = ref(false)
  const showUsersDropdown = ref(false)
  const selectedUsers = ref([])
  const search_users = ref('')
  const search_files = ref('')
  const selectedFiles = ref([])
  const showFilesDropdown = ref(false)

  const fetchCommits = async () => {
    try {
      loading.value = true
      const response = await axios.get('/api/git_commits')

      if (response && response.data && response.data.commits?.length) {
        commits.value = response.data.commits.map(commit => {
          // Parse the date string using the custom format
          const utcDate = parse(commit.date, 'EEE MMM dd HH:mm:ss yyyy xxxx', new Date())

          // Format it to "X days ago"
          const date = formatDistanceToNow(utcDate, { addSuffix: true })

          return {
            ...commit,
            hash: commit.hash,
            message: commit.message,
            date: commit.date,
            formattedDate: date,
            author_name: commit.author_name,
            author_email: commit.author_email,
          }
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const commitToDisplay = computed(() => {
    if (!commits.value) return []

    return commits.value.filter(commit => {
      // search by commit message or author name or author email
      let result =
        commit.message.toLowerCase().includes(search.value.toLowerCase()) ||
        commit.author_name?.toLowerCase().includes(search.value.toLowerCase()) ||
        commit.author_email?.toLowerCase().includes(search.value.toLowerCase()) ||
        commit.files_changed?.some(file => file.toLowerCase().includes(search.value.toLowerCase()))

      // filter by selected users
      if (selectedUsers.value.length) {
        result = result && selectedUsers.value.includes(commit.author_email)
      }

      // filter by selected files
      if (selectedFiles.value.length) {
        result = result && selectedFiles.value.some(file => commit.files_changed.includes(file))
      }

      return result
    })
  })

  const allUsers = computed(() => {
    if (!commits.value) return []

    const users = new Set()

    commits.value?.forEach(commit => {
      users.add(commit.author_email)
    })

    const result = Array.from(users).map(user => {
      return {
        label: user,
        value: user,
        count: commits.value?.filter(commit => commit.author_email === user).length,
      }
    })

    // search_users
    if (search_users.value) {
      return result.filter(user => user.label.toLowerCase().includes(search_users.value.toLowerCase()))
    }

    // sort by selected users first
    result.sort((a, b) => {
      if (selectedUsers.value.includes(a.value) && !selectedUsers.value.includes(b.value)) return -1
      if (!selectedUsers.value.includes(a.value) && selectedUsers.value.includes(b.value)) return 1
      return 0
    })

    return result
  })

  const allFiles = computed(() => {
    if (!commits.value) return []

    const files = new Set()

    commits.value?.forEach(commit => {
      commit.files_changed?.forEach(file => {
        files.add(file)
      })
    })

    const result = Array.from(files).map(file => {
      return {
        label: file,
        value: file,
        count: commits.value?.filter(commit => commit.files_changed.includes(file)).length,
      }
    })

    // search_files
    if (search_files.value) {
      return result.filter(file => file.label.toLowerCase().includes(search_files.value.toLowerCase()))
    }

    // sort by selected files first
    result.sort((a, b) => {
      if (selectedFiles.value.includes(a.value) && !selectedFiles.value.includes(b.value)) return -1
      if (!selectedFiles.value.includes(a.value) && selectedFiles.value.includes(b.value)) return 1
      return 0
    })

    return result
  })

  const toggleUser = user => {
    if (selectedUsers.value.includes(user)) {
      selectedUsers.value = selectedUsers.value.filter(u => u !== user)
    } else {
      selectedUsers.value = [...selectedUsers.value, user]
    }
  }

  const toggleFile = file => {
    if (selectedFiles.value.includes(file)) {
      selectedFiles.value = selectedFiles.value.filter(f => f !== file)
    } else {
      selectedFiles.value = [...selectedFiles.value, file]
    }
  }

  onMounted(() => {
    fetchCommits()
  })
</script>
