<template>
  <section v-if="page === 'email'" class="bg-white dark:bg-[#141414] w-full text-gray-900 dark:text-white">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <router-link to="/" class="mb-6">
        <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
      </router-link>
      <div class="w-full rounded-24 border primary-border max-w-[440px] px-4 py-8 md:px-8 md:py-10">
        <div class="space-y-4 md:space-y-6">
          <h1 class="text-24 font-bold text-center">Password Reset</h1>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="email" class="primary-label">Your email</label>
              <input
                id="email"
                v-model="email"
                type="email"
                name="email"
                class="primary-input"
                placeholder="name@company.com"
                required
              />
            </div>
            <button class="primary-btn !w-full !font-medium" :disabled="requesting_reset_token" @click.prevent="reset">
              <LoadingIcon
                v-if="requesting_reset_token"
                class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin"
              />
              Send Reset Token
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section v-else-if="page === 'verify'" class="bg-white dark:bg-[#141414] w-full text-gray-900 dark:text-white">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <router-link to="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
        <img alt="Dot" class="w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
      </router-link>
      <div class="w-full rounded-24 border primary-border max-w-[440px] px-4 py-8 md:px-8 md:py-10">
        <div class="space-y-4 md:space-y-6">
          <h1 class="text-24 font-bold text-center">Password Reset</h1>
          <form class="space-y-4 md:space-y-6" action="#">
            <div>
              <label for="password" class="primary-label">New password</label>
              <div class="relative">
                <input
                  id="password"
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  placeholder="••••••••"
                  class="primary-input"
                  required=""
                  @input="validatePassword"
                />

                <button
                  v-tooltip="showPassword ? 'Hide password' : 'Show password'"
                  type="button"
                  class="absolute inset-y-0 right-0 flex items-center pr-3"
                  @click="showPassword = !showPassword"
                >
                  <Icon
                    class="size-4 text-gray-400 dark:text-gray-500"
                    :icon="showPassword ? 'hugeicons:eye' : 'uil:eye-slash'"
                  />
                </button>
              </div>
              <!-- Display password validation error message -->
              <p v-if="passwordError" class="text-sm text-red-500 mt-1">
                {{ passwordError }}
              </p>
            </div>

            <div>
              <label for="token" class="primary-label">Reset Token</label>
              <input
                id="token"
                v-model="token"
                type="text"
                name="token"
                class="primary-input"
                placeholder="123456"
                required=""
              />
            </div>
            <button class="primary-btn !w-full !font-medium" :disabled="saving_password" @click.prevent="verify">
              <LoadingIcon v-if="saving_password" class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin" />
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { onMounted, ref } from 'vue'
  import axios from 'axios'
  import { useRoute } from 'vue-router'
  import { useGlobalStore } from './stores/globalStore'
  import LoadingIcon from './components/icons/LoadingIcon.vue'

  // store login state in local storage
  // https://stackoverflow.com/questions/2010892/storing-objects-in-html5-localstorage
  export default {
    name: 'PasswordPage',
    components: {
      LoadingIcon,
    },
    setup() {
      const route = useRoute()
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const email = ref('')
      const token = ref('')
      const page = ref('email')
      const password = ref('')
      const showPassword = ref(false)

      // Password error message
      const passwordError = ref('')

      /**
       * Validates the password in real-time as the user types.
       * Requirements:
       * - At least 8 characters long
       * - Contains at least one uppercase letter, one lowercase letter,
       *   one digit, and one special character.
       */
      const validatePassword = () => {
        const pw = password.value

        // Construct the pattern
        const uppercase = /[A-Z]/
        const lowercase = /[a-z]/
        const digit = /\d/
        // eslint-disable-next-line no-useless-escape
        const specialChar = /[\[\]@#$!%*?&^()_\-+={};:"'<>,.~]/

        if (pw.length < 8) {
          passwordError.value = 'Password must be at least 8 characters long.'
          return
        }
        if (!uppercase.test(pw)) {
          passwordError.value = 'Password must contain at least one uppercase letter.'
          return
        }
        if (!lowercase.test(pw)) {
          passwordError.value = 'Password must contain at least one lowercase letter.'
          return
        }
        if (!digit.test(pw)) {
          passwordError.value = 'Password must contain at least one number.'
          return
        }
        if (!specialChar.test(pw)) {
          passwordError.value = 'Password must contain at least one special character (e.g., @, #, $).'
          return
        }

        // If all checks pass, clear the error
        passwordError.value = ''
      }

      onMounted(() => {
        if (route.name === 'Invite' && route.params.email && route.params.token) {
          email.value = route.params.email
          token.value = route.params.token
          page.value = 'verify'
        }
      })

      return {
        email,
        token,
        page,
        notify,
        password,
        passwordError,
        showPassword,
        validatePassword,
      }
    },
    data() {
      return {
        message: '',
        isSuccess: true,
        requesting_reset_token: false,
        saving_password: false,
      }
    },
    methods: {
      async reset() {
        // get inputs fields
        const email = this.email

        if (email === '') {
          this.notify.error('Please fill in all fields')
          return
        }

        // call /auth/token login api with email and password using axios
        try {
          this.requesting_reset_token = true
          const response = await axios.post(
            '/api/auth/send_password_reset',
            { username: email },
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )
          const { data } = response
          console.log(data)
          if (response.status === 200) {
            // store login state in local storage
            this.notify.success('Reset token sent to your email 📨')
            this.page = 'verify'
          }
        } catch (error) {
          this.notify.error(error.response.data.detail)
          console.log('login failed')
        } finally {
          this.requesting_reset_token = false
        }
      },
      async verify() {
        const email = this.email
        const password = this.password
        const token = this.token

        if (password === '' || token === '') {
          this.notify.error('Please fill in all fields')
          return
        }

        // If there's any password validation error, stop
        if (this.passwordError !== '') {
          this.notify.error(this.passwordError)
          return
        }

        // call /api/auth/reset_password api with email and password using axios
        try {
          this.saving_password = true
          const response = await axios.post(
            '/api/auth/reset_password',
            { username: email, password, token },
            {
              headers: { 'Content-Type': 'application/json' },
            }
          )
          const { data } = response
          console.log(data)
          if (response.status === 200) {
            // store login state in local storage
            this.notify.success('Password reset successful')
            // Redirect to login page after 3 seconds
            setTimeout(() => {
              this.$router.push({ name: 'Login' })
            }, 3000)
          }
        } catch (error) {
          this.notify.error(error.response.data.detail)
        } finally {
          this.saving_password = false
        }
      },
    },
  }
</script>

<style></style>
