<template>
  <div>
    <h1>Logout</h1>
  </div>
</template>

<script>
  import { useUserStore } from '@/stores/userStore'

  export default {
    name: 'LogoutPage',
    mounted() {
      console.log('logout')
      localStorage.removeItem('login')
      // Call the logout function from the user store
      const userStore = useUserStore()
      userStore.logout()
    },
  }
</script>

<style></style>
