/**
 * Handles shift+click selection for checkboxes with multi-deselect functionality.
 *
 * @param {number} currentIndex - The index of the currently clicked checkbox.
 * @param {object} currentItem - The current item being clicked.
 * @param {Array} items - The array of items in the table.
 * @param {string} toggleProperty - The property to toggle (e.g., 'active').
 * @param {number|null} lastSelectedIndex - The index of the last selected checkbox.
 * @param {function} updateLastIndex - Callback to update the last selected index.
 * @param {Event} event - The click event.
 * @param {function} action - The action to perform on the items. Receives the items array as an argument.
 */
export function handleShiftClickSelection(
  currentIndex,
  currentItem,
  items,
  toggleProperty,
  lastSelectedIndex,
  updateLastIndex,
  event,
  action
) {
  const allItemsInRange = []
  if (lastSelectedIndex !== null && event.shiftKey) {
    // Determine the range to select/deselect
    const start = Math.min(lastSelectedIndex, currentIndex)
    const end = Math.max(lastSelectedIndex, currentIndex)

    // Check if all items in the range are currently selected
    // don't check disabled if it doesnt have the property
    const allSelected = items.slice(start, end + 1).every(item => {
      if (Object.prototype.hasOwnProperty.call(item, 'disabled')) {
        return !item.disabled && item[toggleProperty]
      }
      return item[toggleProperty]
    })

    // Toggle the selected property for all items in the range
    for (let i = start; i <= end; i++) {
      if (Object.prototype.hasOwnProperty.call(items[i], 'disabled')) {
        if (!items[i].disabled) {
          items[i][toggleProperty] = !allSelected // Deselect if all are selected, else select
          allItemsInRange.push(items[i])
        }
      } else {
        items[i][toggleProperty] = !allSelected // Deselect if all are selected, else select
        allItemsInRange.push(items[i])
      }
    }
  } else {
    // Toggle the current item's property
    currentItem[toggleProperty] = !currentItem[toggleProperty]
    allItemsInRange.push(currentItem)
  }

  // Update the last selected index
  updateLastIndex(currentIndex)

  // if action is provided, call it with the array of only the items that were selected/deselected
  if (action) {
    action(allItemsInRange)
  }
}
