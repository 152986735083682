<template>
  <div class="overflow-y-auto bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full min-h-screen">
    <div class="px-[24px] pb-12 container">
      <div class="w-full flex py-4 mb-4 sm:items-center justify-between flex-col sm:flex-row gap-4 sm:gap-0">
        <h1 class="text-2xl font-bold text-left">History</h1>
      </div>
      <div class="w-full">
        <div class="w-full flex items-center">
          <h2 class="text-left">Messages · {{ totalMessagesLength }}</h2>
          <div class="items-center sm:ml-auto">
            <select
              id="month"
              v-model="selectedYearMonth"
              class="bg-gray-50 border border-gray-200 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1 px-2 dark:bg-gray-900 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option v-for="month in availableMonths" :key="month" :value="month">
                {{ formatDate(month + '-01') }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full overflow-x-auto rounded-2xl mt-2">
          <PlotlyChart
            v-if="chartConfig"
            :chart-config="chartConfig"
            :show_controls="false"
            class="w-full min-w-[620px]"
          />
        </div>
      </div>

      <!-- History Table -->
      <div v-if="!chats?.length" class="mt-[10%]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="0.5"
          stroke="currentColor"
          class="w-12 h-12 mx-auto"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z"
          />
        </svg>
        <h1 class="text-center mt-4">Nothing to remember, yet.</h1>
      </div>
      <div v-else class="mt-10">
        <!-- Ref to handle scrolling to top of the table -->
        <div class="flex lg:items-center lg:justify-between flex-col lg:flex-row gap-4">
          <Search
            :model-value="search_input"
            placeholder="Search..."
            @update:model-value="handleSearchUpdate($event)"
          />
          <div class="flex items-center gap-4 w-full lg:w-max lg:shrink-0 flex-wrap">
            <SelectInput
              id="history-filter"
              :selected-value="`Filter by: ${current_filter}`"
              :options="filters"
              align-dropdown="left"
              :is-multiple="false"
              :disabled="loading"
              :used-in-table="false"
              @select="val => changeFilter(val)"
            />
            <SelectInput
              v-if="columns_to_show.labels || selected_labels.length"
              id="label-filter"
              :selected-value="selectedLabelFilter"
              :selected-values="selected_labels"
              :options="allLabelOptions"
              align-dropdown="right"
              :is-multiple="true"
              :disabled="loading"
              :used-in-table="false"
              @select="val => toggleSelectedLabel(val)"
            />
            <SelectInput
              :id="`columns-to-show`"
              :selected-value="selectedValue"
              :selected-values="selectedColumns"
              :options="all_columns"
              align-dropdown="right"
              :is-multiple="true"
              :disabled="loading"
              :used-in-table="false"
              @select="val => handleToggleColumns(val)"
            />
          </div>
        </div>

        <div class="table-wrapper">
          <table class="primary-table">
            <thead class="table-header sm:rounded-t-xl">
              <tr>
                <th scope="col" class="px-6 py-3">
                  <div class="flex items-center">NO.</div>
                </th>
                <th v-if="columns_to_show.title" scope="col" class="px-6 py-3">
                  <div class="flex items-center">Title</div>
                </th>
                <th v-if="columns_to_show.user" scope="col" class="px-6 py-3">
                  <div class="flex items-center">User</div>
                </th>
                <th
                  v-if="columns_to_show.last_updated"
                  scope="col"
                  class="px-6 py-3 cursor-pointer w-max"
                  @click="sortBy('last_updated')"
                >
                  <div class="flex items-center gap-2.5 w-max">
                    <span>Last Updated</span>
                    <div class="flex flex-col justify-center items-center -space-y-1">
                      <Icon
                        icon="mynaui:chevron-up-solid"
                        class="w-[14px]"
                        :class="
                          sort_by === 'last_updated' && sort_order === 'asc'
                            ? 'text-gray-500 dark:text-gray-200'
                            : 'text-gray-400 dark:text-gray-500'
                        "
                      />
                      <Icon
                        icon="mynaui:chevron-down-solid"
                        class="w-[14px]"
                        :class="
                          sort_by === 'last_updated' && sort_order === 'desc'
                            ? 'text-gray-500 dark:text-gray-200'
                            : 'text-gray-400 dark:text-gray-500'
                        "
                      />
                    </div>
                  </div>
                </th>
                <th
                  v-if="columns_to_show.date_created"
                  scope="col"
                  class="px-6 py-3 cursor-pointer w-max"
                  @click="sortBy('date')"
                >
                  <div class="flex items-center gap-2.5 w-max">
                    <span>Date Created</span>
                    <div class="flex flex-col justify-center items-center -space-y-1">
                      <Icon
                        icon="mynaui:chevron-up-solid"
                        class="w-[14px]"
                        :class="
                          sort_by === 'date' && sort_order === 'asc'
                            ? 'text-gray-500 dark:text-gray-200'
                            : 'text-gray-400 dark:text-gray-500'
                        "
                      />
                      <Icon
                        icon="mynaui:chevron-down-solid"
                        class="w-[14px]"
                        :class="
                          sort_by === 'date' && sort_order === 'desc'
                            ? 'text-gray-500 dark:text-gray-200'
                            : 'text-gray-400 dark:text-gray-500'
                        "
                      />
                    </div>
                  </div>
                </th>
                <th v-if="columns_to_show.labels" scope="col" class="px-6 py-3">
                  <div class="flex items-center">Labels</div>
                </th>
                <th v-if="columns_to_show.messages" scope="col" class="px-2 py-3 text-center">Messages</th>
                <th v-if="columns_to_show.feedback" scope="col" class="px-2 py-3 text-center">Feedback</th>
                <th v-if="columns_to_show.scheduled" scope="col" class="px-2 py-3 text-center">Scheduled</th>
                <th v-if="columns_to_show.shared" scope="col" class="px-2 py-3 text-center sm:rounded-tr-xl">Shared</th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr
                v-for="(item, index) in filteredItems"
                :key="item.id"
                class="bg-white dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900 cursor-pointer"
                @click.stop="$router.push({ path: '/', query: { c: item.id } })"
              >
                <td scope="row" class="w-max px-6 py-4">
                  <span class="text-right w-max">
                    {{ page - 1 > 0 ? (page - 1) * perPage + index + 1 : index + 1 }}
                  </span>
                </td>
                <td v-if="columns_to_show.title" alt="item.name" scope="row" class="max-w-md">
                  <router-link
                    v-tooltip="{
                      content: item.first_question ? `First question: ` + item.first_question : item.title,
                      placement: 'right',
                    }"
                    :to="{ path: '/', query: { c: item.id } }"
                    class="pl-6 pr-2 py-4 text-xs block truncate font-medium text-gray-900 whitespace-nowrap dark:text-white w-full"
                  >
                    {{ item.title ? item.title : item.first_question }}
                  </router-link>
                </td>
                <td v-if="columns_to_show.user" class="max-w-xs">
                  <router-link
                    :to="{ path: '/', query: { c: item.id } }"
                    class="pl-6 pr-2 py-4 text-xs truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400 block w-full"
                  >
                    {{ item.user_id }}
                  </router-link>
                </td>
                <td
                  v-if="columns_to_show.last_updated"
                  v-tooltip="{
                    content: `<div style='display: table; text-align: left;'>
                <div style='display: table-row;'>
                  <span style='display: table-cell; padding-right: 8px;'><strong>Last updated</strong></span>
                  <span style='display: table-cell; font-family: Roboto Mono, monospace;'>${new Date(item.last_updated).toLocaleString()}</span>
                </div>
                <div style='display: table-row;'>
                  <span style='display: table-cell; padding-right: 8px;'><strong>Created</strong></span>
                  <span style='display: table-cell; font-family: Roboto Mono, monospace;'>${new Date(item.date).toLocaleString()}</span>
                </div>
              </div>`,
                    html: true,
                    placement: 'top',
                  }"
                  class="max-w-xs"
                >
                  <router-link
                    :to="{ path: '/', query: { c: item.id } }"
                    class="pl-6 pr-2 py-4 text-xs block w-full truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
                  >
                    {{ getTimeAgo(item.last_updated || item.date) }}
                  </router-link>
                </td>
                <td v-if="columns_to_show.date_created" class="max-w-xs">
                  <router-link
                    :to="{ path: '/', query: { c: item.id } }"
                    class="pl-6 pr-2 py-4 text-xs block w-full truncate text-ellipsis font-medium text-gray-500 whitespace-nowrap dark:text-gray-400"
                  >
                    {{ getTimeAgo(item.date) }}
                  </router-link>
                </td>
                <td v-if="columns_to_show.labels" class="max-w-xs" @click.stop>
                  <div
                    class="pl-5 pr-2 flex items-center flex-row flex-wrap gap-1 overflow-y-auto max-h-[100px] hide-scrollbar show-scrollbar"
                  >
                    <span
                      v-for="(label, label_idx) in item.labels"
                      :key="label_idx"
                      v-tooltip="`Remove ${label}`"
                      class="py-1 px-3 font-mono font-medium text-xs text-white bg-gray-950 hover:bg-gray-600 dark:text-dark-text dark:bg-white dark:hover:bg-gray-400 rounded-full flex items-center gap-1 w-max cursor-pointer"
                      @click="removeLabelFromChat(item, label)"
                    >
                      {{ label }}
                      <Icon icon="material-symbols:close-rounded" class="w-[14px]" />
                    </span>
                    <div v-if="item" class="grow">
                      <input
                        id="addLabels"
                        v-model="textInput[item.id]"
                        :placeholder="item.labels.length ? 'Add another label' : 'Add label'"
                        autocomplete="off"
                        class="block w-full text-xs border-0 font-mono font-medium outline-none p-0 pl-1 py-2 focus:ring-0 text-gray-900 dark:text-white bg-transparent"
                        type="text"
                        @keydown.tab.stop.prevent="addAutocomplete(item, $event.target.value)"
                        @keyup="
                          $event => {
                            showSuggestion(item)

                            if (!['Enter', ' ', ','].includes($event.key)) {
                              return
                            }
                            addLabelToChat(item, $event.target.value)
                          }
                        "
                      />
                      <div v-if="autoCompPlaceholder[item.id]" class="clearit virtual-text">
                        <div class="hidden-text text-xs font-mono font-medium">
                          {{ textInput[item.id] }}
                        </div>
                        <input
                          :placeholder="autoCompPlaceholder[item.id]"
                          class="autocomplete text-xs font-mono font-medium"
                          type="text"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </td>
                <td v-if="columns_to_show.messages" class="max-w-xs">
                  <router-link
                    v-tooltip="{
                      content: `<div style='display: table; text-align: left;'>
                <div style='display: table-row;'>
                  <span style='display: table-cell; padding-right: 8px;'><strong>Total messages:</strong></span>
                  <span style='display: table-cell; font-family: Roboto Mono, monospace;'>${getMessageNumber(item.messages_length) + (item.edits_length || 0)}</span>
                </div>
                <div style='display: table-row;'>
                  <span style='display: table-cell; padding-right: 8px;'><strong>Number of edits:</strong></span>
                  <span style='display: table-cell; font-family: Roboto Mono, monospace;'>${item.edits_length || 0}</span>
                </div>
              </div>`,
                      html: true,
                      placement: 'top',
                    }"
                    :to="{ path: '/', query: { c: item.id } }"
                    class="px-2 py-4 text-xs block w-full truncate text-ellipsis font-medium text-gray-500 text-center dark:text-gray-400"
                  >
                    {{ getMessageNumber(item.messages_length) + (item.edits_length || 0) }}
                  </router-link>
                </td>
                <td v-if="columns_to_show.feedback" class="max-w-xs">
                  <router-link
                    :to="{ path: '/', query: { c: item.id } }"
                    class="px-1 py-2 text-xs block w-full truncate text-ellipsis font-medium text-center"
                  >
                    <span
                      class="rounded-full px-2 py-1 bg-opacity-20 dark:bg-opacity-10"
                      :class="
                        item.overall_feedback > 0
                          ? 'text-green-700 dark:text-green-300 bg-green-400 dark:bg-green-300'
                          : item.overall_feedback < 0
                            ? 'text-red-700 dark:text-red-300 bg-red-400 dark:bg-red-300'
                            : 'text-gray-500 dark:text-gray-400'
                      "
                    >
                      {{ item.overall_feedback > 0 ? 'Success' : item.overall_feedback < 0 ? 'Problem' : '-' }}
                    </span>
                  </router-link>
                </td>

                <td
                  v-if="columns_to_show.scheduled"
                  class="max-w-xs p-1 text-xs font-medium text-gray-500 text-center dark:text-gray-400"
                >
                  <div class="relative" @click.stop>
                    <button
                      v-if="item.schedules && JSON.parse(item.schedules)?.length > 0"
                      class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-xl hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                      type="button"
                      @click="$refs[`scheduled-link-${item.id}`][0].classList.toggle('hidden')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                        />
                      </svg>
                    </button>

                    <div
                      :ref="`scheduled-link-${item.id}`"
                      v-click-away="
                        () => {
                          const ref = $refs[`scheduled-link-${item.id}`]
                          if (ref && !ref[0].classList.contains('hidden')) {
                            ref[0].classList.add('hidden')
                          }
                        }
                      "
                      class="z-10 hidden absolute right-0 top-[calc(100% + 10px)] bg-white divide-y divide-gray-100 rounded-xl shadow w-44 dark:bg-gray-800 dark:divide-gray-700 overflow-hidden"
                    >
                      <ul
                        class="text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownMenuIconHorizontalButton"
                      >
                        <li>
                          <button
                            class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                            @click.stop="
                              (deleteSchedule(item), $refs[`scheduled-link-${item.id}`][0].classList.toggle('hidden'))
                            "
                          >
                            Delete Schedule
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>

                <td
                  v-if="columns_to_show.shared"
                  class="max-w-xs p-1 text-xs font-medium text-gray-500 text-center dark:text-gray-400"
                >
                  <!-- Dropdown button -->
                  <div class="relative" @click.stop>
                    <button
                      v-if="item.public"
                      class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-xl hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                      type="button"
                      @click="$refs[`shared-link-${item.id}`][0].classList.toggle('hidden')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2.0"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                        />
                      </svg>
                    </button>

                    <!-- Dropdown menu -->
                    <div
                      :ref="`shared-link-${item.id}`"
                      v-click-away="
                        () => {
                          const ref = $refs[`shared-link-${item.id}`]
                          if (ref && !ref[0].classList.contains('hidden')) {
                            ref[0].classList.add('hidden')
                          }
                        }
                      "
                      class="z-10 hidden absolute right-0 top-[calc(100% + 10px)] bg-white divide-y divide-gray-100 rounded-xl shadow w-44 dark:bg-gray-800 dark:divide-gray-700 overflow-hidden"
                    >
                      <ul
                        class="text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownMenuIconHorizontalButton"
                      >
                        <li>
                          <button
                            class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                            @click.stop="
                              (get_shared_link(item.id), $refs[`shared-link-${item.id}`][0].classList.toggle('hidden'))
                            "
                          >
                            Copy Link
                          </button>
                        </li>
                        <li>
                          <button
                            class="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white"
                            @click.stop="
                              (delete_shared_link(item), $refs[`shared-link-${item.id}`][0].classList.toggle('hidden'))
                            "
                          >
                            Delete Link
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-wrapper">
          <!-- Help text -->
          <div class="text-sm flex items-center gap-1">
            <span class="text-gray-500 dark:text-gray-400 flex items-center gap-1">
              Showing
              <span class="font-semibold text-gray-600 dark:text-white">
                {{ filteredItems.length > 0 ? (page - 1) * perPage + 1 : 0 }}
              </span>
              to
              <span class="font-semibold text-gray-600 dark:text-white">
                {{ itemsShown }}
              </span>
              of
              <span class="font-semibold text-gray-600 dark:text-white">{{ allItems }}</span>
            </span>
            <span class="text-gray-500 dark:text-gray-400 w-max shrink-0">· Rows per page</span>
            <select
              id="perPage"
              v-model="perPage"
              class="ml-2 cursor-pointer max-w-[80px] bg-gray-50 border border-gray-200 text-gray-800 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @change="
                () => {
                  page = 1
                  $router.push({ query: { ...$route.query, page: 1, perPage: perPage } })
                }
              "
            >
              <option :value="10">10</option>
              <option :value="20" selected>20</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
              <option :value="500">500</option>
              <option :value="chats.length">All</option>
            </select>
          </div>

          <div class="sm:ml-auto pagination-btn-wrapper">
            <!-- Buttons -->
            <button class="pagination-btn-prev" @click="handlePrevPage">
              <Icon icon="hugeicons:arrow-left-01" class="w-4 h-4" />
              Prev
            </button>
            <button class="pagination-btn-next" @click="handleNextPage">
              Next
              <Icon icon="hugeicons:arrow-right-01" class="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
      <!-- Table for data end -->

      <div class="flex-grow" />
    </div>
  </div>
</template>

<script>
  import axios from '@/axiosInstance'
  import { useUserStore } from '@/stores/userStore'
  import { storeToRefs } from 'pinia'
  import { format, formatDistanceToNow, parseISO } from 'date-fns'
  import { useGlobalStore } from './stores/globalStore'
  import Search from './components/Search.vue'
  import PlotlyChart from '@/components/PlotlyChart.vue'

  export default {
    name: 'HistoryPage',
    components: {
      PlotlyChart,
      Search,
    },
    setup() {
      const userStore = useUserStore()
      const { user } = storeToRefs(userStore)
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      return {
        userStore,
        user,
        notify,
      }
    },
    data() {
      let columns_to_show = {
        title: true,
        user: true,
        last_updated: true,
        date_created: false,
        labels: false,
        messages: true,
        feedback: true,
        scheduled: true,
        shared: true,
      }

      try {
        if (localStorage.getItem('columns_to_show')) {
          columns_to_show = JSON.parse(localStorage.getItem('columns_to_show'))
        }
      } catch (error) {
        console.error('Failed to parse columns_to_show from localStorage:', error)
      }

      // get filter from router query
      const filter = this.$route.query.filter || 'all'

      // get selected_labels from router query
      let selected_labels = []
      if (this.$route.query.labels) {
        selected_labels = this.$route.query.labels.split(',')
      }

      // get search_input from router query
      const search_input = this.$route.query.q || ''

      // get page from router query
      const page = Number(this.$route.query.page) || 1

      // get perPage from router query
      const perPage = Number(this.$route.query.perPage) || 20

      // get sort_by from router query
      const sort_by = this.$route.query.sort_by || 'last_updated'

      // get sort_order from router query
      const sort_order = this.$route.query.sort_order || 'asc'

      return {
        chats: [{ first_question: 'Loading..' }],
        loading: false,
        search_input,
        // initate selectedYearMonth as YYYY-MM for current month
        selectedYearMonth: format(new Date(), 'yyyy-MM'),
        message: '',
        isSuccess: false,
        chartOptions: {
          theme: {
            monochrome: {
              enabled: true,
              color: '#FFA500', // Font color
              shadeTo: 'light',
              shadeIntensity: 0.65,
            },
          },
          grid: {
            strokeDashArray: [1, 5], // Solid lines
            borderColor: 'rgba(120,120,120,0.5)', // 60% opacity
          },
          chart: {
            id: 'vuechart-example',
            width: '100%',
            toolbar: {
              show: false,
            },
          },
          tooltip: {
            theme: 'dark',
          },
          dataLabels: {
            enabled: false,
          },

          xaxis: {
            labels: {
              formatter: value => {
                try {
                  return format(parseISO(value), 'dd MMM')
                } catch {
                  return value
                }
              },
              style: {
                colors: '#969696', // Font color for X-axis labels
              },
            },
            tickAmount: 10, // Number of ticks on x-axis
          },
          yaxis: {
            labels: {
              style: {
                colors: '#969696', // Font color for Y-axis labels
              },
              formatter: value => {
                // Ensure the value is a whole integer
                return Math.round(value)
              },
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 3,
            },
          },
        },
        series: [],
        total_messages: 0,
        chartConfig: null,
        current_filter: filter,
        filters: [
          { label: 'All', value: 'all' },
          { label: 'Scheduled', value: 'scheduled' },
          { label: 'Shared', value: 'shared' },
          { label: 'Liked', value: 'liked' },
          { label: 'Disliked', value: 'disliked' },
        ],
        chart_data: [],
        all_columns: [
          { label: 'Title', value: 'title' },
          { label: 'User', value: 'user' },
          { label: 'Last Updated', value: 'last_updated' },
          { label: 'Date Created', value: 'date_created' },
          { label: 'Labels', value: 'labels' },
          { label: 'Messages', value: 'messages' },
          { label: 'Feedback', value: 'feedback' },
          { label: 'Scheduled', value: 'scheduled' },
          { label: 'Shared', value: 'shared' },
        ],
        columns_to_show,
        textInput: {},
        autoCompPlaceholder: {},
        page: Number(page),
        perPage: Number(perPage),
        selected_labels,
        sort_by,
        sort_order,
      }
    },
    computed: {
      filteredItems() {
        let sorted = this.chats.slice()

        // sort by sort_by, default is last_updated
        if (this.sort_by === 'last_updated') {
          sorted.sort((a, b) => {
            if (this.sort_order === 'desc') {
              return new Date(a.last_updated || a.date) - new Date(b.last_updated || b.date)
            } else {
              return new Date(b.last_updated || b.date) - new Date(a.last_updated || a.date)
            }
          })
        } else if (this.sort_by === 'date') {
          sorted.sort((a, b) => {
            if (this.sort_order === 'desc') {
              return new Date(a.date) - new Date(b.date)
            } else {
              return new Date(b.date) - new Date(a.date)
            }
          })
        } else {
          sorted.sort((a, b) => {
            if (this.sort_order === 'desc') {
              return new Date(b.last_updated || b.date) - new Date(a.last_updated || a.date)
            } else {
              return new Date(a.last_updated || a.date) - new Date(b.last_updated || b.date)
            }
          })
        }

        // filter by user_id or first_question or title
        sorted = sorted.filter(item => {
          return (
            (item.user_id && item.user_id.toLowerCase().includes(this.search_input.toLowerCase())) ||
            (item.first_question && item.first_question.toLowerCase().includes(this.search_input.toLowerCase())) ||
            (item.title && item.title.toLowerCase().includes(this.search_input.toLowerCase())) ||
            (item.overall_feedback &&
              (item.overall_feedback > 0 ? 'success' : 'problem').includes(this.search_input.toLowerCase()))
          )
        })

        // add labels to sorted
        sorted = sorted.map(item => {
          try {
            return { ...item, labels: item.labels ? JSON.parse(item.labels) : [] }
          } catch (error) {
            console.error(`Failed to parse labels for item ${item.id}:`, error)
            return { ...item, labels: [] }
          }
        })

        // filter by selected_labels
        if (this.selected_labels.length > 0) {
          sorted = sorted.filter(item => {
            return this.selected_labels.some(label => item.labels.includes(label))
          })
        }

        let filtered = []
        switch (this.current_filter) {
          case 'all':
            filtered = sorted
            break
          case 'scheduled':
            filtered = sorted.filter(chat => {
              try {
                return JSON.parse(chat.schedules)?.length > 0
              } catch (error) {
                console.error('Failed to parse schedules for chat:', error)
                return false
              }
            })
            break
          case 'shared':
            filtered = sorted.filter(chat => chat.public)
            break
          case 'liked':
            filtered = sorted.filter(chat => chat.overall_feedback > 0)
            break
          case 'disliked':
            filtered = sorted.filter(chat => chat.overall_feedback < 0)
            break
          default:
            filtered = sorted
        }

        // Apply pagination
        const start = (this.page - 1) * this.perPage
        const end = start + this.perPage
        filtered = filtered.slice(start, end)

        return filtered
      },
      filteredItemsWithoutPagination() {
        let sorted = this.chats.slice()

        // filter by user_id or first_question or title
        sorted = sorted.filter(item => {
          return (
            (item.user_id && item.user_id.toLowerCase().includes(this.search_input.toLowerCase())) ||
            (item.first_question && item.first_question.toLowerCase().includes(this.search_input.toLowerCase())) ||
            (item.title && item.title.toLowerCase().includes(this.search_input.toLowerCase())) ||
            (item.overall_feedback &&
              (item.overall_feedback > 0 ? 'success' : 'problem').includes(this.search_input.toLowerCase()))
          )
        })

        // add labels to sorted
        sorted = sorted.map(item => {
          try {
            return { ...item, labels: item.labels ? JSON.parse(item.labels) : [] }
          } catch (error) {
            console.error(`Failed to parse labels for item ${item.id}:`, error)
            return { ...item, labels: [] }
          }
        })

        // filter by selected_labels
        if (this.selected_labels.length > 0) {
          sorted = sorted.filter(item => {
            return this.selected_labels.some(label => item.labels.includes(label))
          })
        }

        let filtered = []
        switch (this.current_filter) {
          case 'all':
            filtered = sorted
            break
          case 'scheduled':
            filtered = sorted.filter(chat => {
              try {
                return JSON.parse(chat.schedules)?.length > 0
              } catch (error) {
                console.error('Failed to parse schedules for chat:', error)
                return false
              }
            })
            break
          case 'shared':
            filtered = sorted.filter(chat => chat.public)
            break
          case 'liked':
            filtered = sorted.filter(chat => chat.overall_feedback > 0)
            break
          case 'disliked':
            filtered = sorted.filter(chat => chat.overall_feedback < 0)
            break
          default:
            filtered = sorted
        }

        return filtered
      },
      availableMonths() {
        if (this.chats.length === 0 || this.chats[0].date === undefined) {
          return []
        } else {
          const months = Array.from(new Set(this.chats.map(item => format(parseISO(item.date), 'yyyy-MM'))))
          const currentMonth = format(new Date(), 'yyyy-MM')
          if (!months.includes(currentMonth)) {
            months.push(currentMonth)
          }
          return months.sort()
        }
      },
      totalMessagesLength() {
        try {
          return this.total_messages
        } catch (error) {
          console.log(error)
          return 0
        }
      },
      selectedValue() {
        // get the number of columns that are true
        const shown_columns = Object.values(this.columns_to_show).filter(Boolean).length

        if (shown_columns === 0) {
          return `Showing columns: 1/${this.all_columns.length + 1}`
        } else if (shown_columns < this.all_columns.length) {
          return `Showing columns: ${shown_columns + 1}/${this.all_columns.length + 1}`
        } else {
          return `Showing columns: all`
        }
      },
      selectedColumns() {
        // get the columns that are true
        const shown_columns = Object.keys(this.columns_to_show).filter(key => this.columns_to_show[key])
        return shown_columns
      },
      allLabels() {
        let labels = new Set()
        this.chats.forEach(chat => {
          if (chat.labels) {
            const parsed_labels = JSON.parse(chat.labels)
            parsed_labels.forEach(label => {
              labels.add(label)
            })
          }
        })
        return Array.from(labels)
      },
      allLabelOptions() {
        // get the number of chats with each label
        const label_counts = this.chats.reduce((acc, chat) => {
          if (chat.labels) {
            try {
              const parsed_labels = JSON.parse(chat.labels)
              parsed_labels.forEach(label => {
                acc[label] = (acc[label] || 0) + 1
              })
            } catch (error) {
              console.error(`Failed to parse labels for item ${chat.id}:`, error)
            }
          }
          return acc
        }, {})
        return Object.entries(label_counts).map(([label, count]) => ({ label, value: label, sub_label: count }))
      },
      itemsShown() {
        return this.filteredItemsWithoutPagination.length > this.page * this.perPage
          ? this.page * this.perPage
          : this.filteredItemsWithoutPagination.length
      },
      allItems() {
        return this.filteredItemsWithoutPagination.length
      },
      selectedLabelFilter() {
        if (this.selected_labels.length === 0) {
          return 'Showing labels: all'
        }

        if (this.selected_labels.length === 1) {
          return `Showing label: ${this.selected_labels[0]}`
        } else {
          return `Showing labels: ${this.selected_labels[0]} and ${this.selected_labels.length - 1} more`
        }
      },
    },

    watch: {
      selectedYearMonth() {
        this.generateMonthlyData()
      },
    },
    mounted() {
      document.title = 'History · Dot'
      this.fetchChartData()
    },
    created() {
      this.load_chats()
    },
    methods: {
      formatDate(date) {
        return format(parseISO(date), 'MMM yyyy')
      },
      delete_shared_link(item) {
        const chat_id = item.id
        item.public = false

        // call api /api/delete_shared_link
        axios
          .post('/api/delete_shared_chat', { chat_id: chat_id })
          .then(() => {
            this.notify.success('Link deleted!')
          })
          .catch(error => {
            console.log(error)
          })
      },
      get_shared_link(chat_id) {
        // load org_id from user
        const org_id = this.user.org_id
        // build link
        const share_link = `${window.location.origin}/share/${chat_id}?org_id=${org_id}`

        // copy link to clipboard
        navigator.clipboard.writeText(share_link).then(
          () => {
            console.log('Text copied to clipboard: ' + share_link)
            // notify user that link is copied
            this.notify.success('Link copied!')
          },
          err => {
            console.error('Could not copy text: ', err)
          }
        )
      },
      async fetchChartData() {
        if (!!this.user && JSON.stringify(this.user) !== '{}' && this.user.role !== 'admin') {
          return
        }

        try {
          const response = await axios.get('/api/daily_usage')
          if (response.data) {
            this.chart_data = response.data
            this.generateMonthlyData()
          }
        } catch (error) {
          console.log(error)
        }
      },
      getTimeAgo(dateString) {
        if (!dateString) {
          return ''
        }

        const date = new Date(dateString) // Make sure the input date is treated as UTC

        // No need to convert to user's timezone if you just want to find the elapsed time
        // Because elapsed time is the same regardless of timezone

        // Calculate the time ago
        return formatDistanceToNow(date, { addSuffix: true })
      },
      async generateMonthlyData() {
        try {
          // Get the selected year and month
          const [year, month] = this.selectedYearMonth.split('-').map(Number)

          let data = this.chart_data

          if (data.length === 0) {
            // Fetch data from the backend
            const response = await axios.get('/api/daily_usage')
            if (response.data) {
              this.chart_data = response.data
              data = response.data
            }
          }

          // Create a dictionary for efficient lookup
          const dataDict = data.reduce((acc, [date, count]) => {
            acc[date] = count
            return acc
          }, {})

          // Get the first and last day of the month
          let currentDate = new Date(year, month - 1, 1)
          const lastDay = new Date(year, month, 0)

          // Generate data for each day
          const x_values = []
          const y_values = []

          this.total_messages = 0
          while (currentDate <= lastDay) {
            const dateStr = currentDate.toISOString().slice(0, 10)
            const count = dataDict[dateStr] || 0

            x_values.push(dateStr)
            y_values.push(count)

            this.total_messages += count

            currentDate.setDate(currentDate.getDate() + 1)
          }

          // Include the translated template
          const color_palette = ['#FFA500'] // Adjust or replace with your color palette

          const plotlyTemplate = {
            layout: {
              title: {
                font: {
                  size: 18,
                },
                x: 0.02,
                xanchor: 'left',
                y: 0.93,
              },
              barcornerradius: 4,
              height: 250,
              margin: { l: 70, r: 70, t: 80, b: 70 },
              plot_bgcolor: '#FFFFFF',
              paper_bgcolor: '#FFFFFF',
              font: {
                family: 'Inter, sans-serif',
                size: 12,
                color: '#212121',
              },
              colorway: color_palette,
              xaxis: {
                gridcolor: '#f2f2f2',
                linecolor: '#d9d9d9',
                showgrid: false,
                zeroline: true,
                zerolinecolor: '#d9d9d9',
                automargin: true,
              },
              yaxis: {
                gridcolor: '#f2f2f2',
                linecolor: '#d9d9d9',
                showgrid: true,
                zeroline: true,
                zerolinecolor: '#d9d9d9',
                automargin: true,
              },
              yaxis2: {
                showgrid: false,
              },
              hovermode: 'closest',
            },
          }

          // Construct the chartConfig object
          this.chartConfig = {
            data: [
              {
                x: x_values,
                y: y_values,
                type: 'bar',
                name: 'messages',
                marker: {
                  color: '#FFA500',
                },
                hovertemplate: '%{x|%d %b}: %{y}<extra></extra>',
              },
            ],
            layout: {
              template: plotlyTemplate, // Include the template here
              xaxis: {
                type: 'date',
                tickformat: '%d %b',
                tickfont: {
                  color: '#969696',
                },
                nticks: 10,
              },
              yaxis: {
                tickfont: {
                  color: '#969696',
                },
                tickformat: 'd',
              },
              hoverlabel: {
                bgcolor: 'rgba(0,0,0,0.85)',
                font: {
                  color: '#fff',
                },
              },
              margin: { l: 50, r: 50, t: 20, b: 60 },
              showlegend: false,
              height: 200,
            },
            config: {
              displayModeBar: false,
              responsive: true,
            },
          }
        } catch (error) {
          console.error('Error generating monthly data:', error)
        }
      },
      async load_chats() {
        try {
          if (!!this.user && JSON.stringify(this.user) !== '{}' && this.user.role !== 'admin') {
            this.notify.error('You are not authorized to view this page')
            return
          }

          this.loading = true
          const response = await axios.get('/api/chat_history_all')
          this.chats = response.data || []
          this.loading = false
        } catch (error) {
          console.error('Error loading chats:', error)
        }
      },
      getMessageNumber(number) {
        return Math.floor(number / 2)
      },
      changeFilter(filter) {
        this.current_filter = filter
        this.page = 1

        // save to router query
        this.$router.push({ query: { ...this.$route.query, filter: filter, page: 1 } })
      },
      toggleSelectedLabel(label) {
        if (this.selected_labels.includes(label)) {
          this.selected_labels = this.selected_labels.filter(l => l !== label)
        } else {
          this.selected_labels.push(label)
        }
        this.page = 1

        // save to router query
        if (this.selected_labels.length > 0) {
          const labels = this.selected_labels.join(',')
          this.$router.push({ query: { ...this.$route.query, labels, page: 1 } })
        } else {
          // eslint-disable-next-line no-unused-vars
          const { labels: _labels, ...rest } = this.$route.query
          this.$router.push({ query: { ...rest, page: 1 } })
        }
      },
      deleteSchedule(item) {
        try {
          const schedule = JSON.parse(item.schedules)[0]

          let chat_id = item.id
          let job_id = schedule.job_id
          axios
            .post('/api/remove_job', { job_id, chat_id }, { withCredentials: true })
            .then(() => {
              this.notify.success('Chat unscheduled successfully')
              this.load_chats()
            })
            .catch(error => {
              console.log(error)
              this.notify.error('Could not unschedule chat')
            })
        } catch (error) {
          console.log(error)
          this.notify.error('An error occurred while unscheduling chat')
        }
      },
      handleToggleColumns(column) {
        // check if column is already shown
        if (this.columns_to_show[column]) {
          this.columns_to_show[column] = false
        } else {
          this.columns_to_show[column] = true
        }

        // save to local storage
        localStorage.setItem('columns_to_show', JSON.stringify(this.columns_to_show))
      },
      async addLabelToChat(item, label) {
        try {
          const labels = item.labels ? [...item.labels, label] : [label]
          const response = await axios.post('/api/add_label_to_chat', { chat_id: item.id, labels })
          if (response.data) {
            this.notify.success('Label added to chat')
            this.load_chats()
            this.textInput = {}
            this.autoCompPlaceholder = {}
          } else {
            this.notify.error('Could not add label to chat')
          }
        } catch (error) {
          console.log(error)
          this.notify.error('An error occurred while adding label to chat')
        }
      },
      async removeLabelFromChat(item, label) {
        try {
          const labels = item.labels.filter(l => l !== label)
          const response = await axios.post('/api/add_label_to_chat', { chat_id: item.id, labels })
          if (response.data) {
            // temporarily set labels before loading chats
            item.labels = labels

            this.notify.success('Label removed from chat')
            this.textInput = {}
            this.autoCompPlaceholder = {}

            // After load_chats completes, check if we need to update selected_labels
            this.load_chats()
              .then(() => {
                // if the label is not in allLabels, remove it from selected_labels
                if (!this.allLabels.includes(label)) {
                  this.selected_labels = this.selected_labels.filter(l => l !== label)

                  // if label is in query, remove it
                  if (this.$route.query.labels && this.$route.query.labels.includes(label)) {
                    // eslint-disable-next-line no-unused-vars
                    const { labels: _labels, ...rest } = this.$route.query
                    this.$router.push({ query: { ...rest, labels: this.selected_labels.join(',') } })
                  }
                }
              })
              .catch(error => {
                console.error('Error updating selected labels:', error)
              })
          } else {
            this.notify.error('Could not remove label from chat')
          }
        } catch (error) {
          console.error(error)
          this.notify.error('An error occurred while removing label from chat')
        }
      },

      showSuggestion(item) {
        try {
          if (!this.textInput[item.id] || this.textInput[item.id].length === 0) {
            this.autoCompPlaceholder[item.id] = ''
            return
          }

          if (this.allLabels.length === 0) {
            this.autoCompPlaceholder[item.id] = ''
            return
          }

          const possibleMatches = this.allLabels.filter(label => {
            if (!item.labels) {
              return label.toLowerCase().startsWith(this.textInput[item.id].toLowerCase())
            }

            return (
              label.toLowerCase().startsWith(this.textInput[item.id].toLowerCase()) && !item.labels?.includes(label)
            )
          })

          if (possibleMatches.length > 0) {
            this.autoCompPlaceholder[item.id] = possibleMatches[0].replace(this.textInput[item.id], '')
          } else {
            this.autoCompPlaceholder[item.id] = ''
          }
        } catch (error) {
          console.error(error)
          this.autoCompPlaceholder[item.id] = ''
        }
      },

      addAutocomplete(item, label) {
        if (!label) {
          label = this.textInput[item.id]
        }

        label += this.autoCompPlaceholder[item.id]
        label = label.trim().toLowerCase()

        if (!item.labels.includes(label)) {
          this.addLabelToChat(item, label)
        }
      },
      sortBy(column) {
        this.sort_by = column
        this.sort_order = this.sort_order === 'asc' ? 'desc' : 'asc'

        this.$router.push({ query: { ...this.$route.query, sort_by: column, sort_order: this.sort_order } })
      },
      handleNextPage() {
        const new_page = Math.min(this.page + 1, Math.max(Math.ceil(this.allItems / this.perPage), 1))
        this.page = new_page
        this.$router.push({ query: { ...this.$route.query, page: new_page } })
      },
      handlePrevPage() {
        const new_page = Math.max(this.page - 1, 1)
        this.page = new_page
        this.$router.push({ query: { ...this.$route.query, page: new_page } })
      },
      handleSearchUpdate(event) {
        this.search_input = event
        this.page = 1
        this.$router.push({ query: { ...this.$route.query, q: event, page: 1 } })
      },
    },
  }
</script>
