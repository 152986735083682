function formatHash(hash) {
  if (!hash) return ''
  if (hash.length < 10) return hash

  const start = hash.slice(0, 4)
  const end = hash.slice(-4)
  return `${start}...${end}`
}

const placeholder = 'N/A'

export { formatHash, placeholder }
