<template>
  <div class="w-full mt-4">
    <div class="w-full flex items-center gap-4">
      <p class="">Schedule sync</p>
      <ToggleButton
        :active="activeSchedule[connection?.type] || active_schedule?.job_id"
        @update:active="
          val => {
            activeSchedule[connection?.type] = val

            // If the schedule is being disabled, delete the schedule
            if (!val && active_schedule?.job_id) {
              deleteSchedule(active_schedule?.job_id)
            }
          }
        "
      />
    </div>

    <div v-if="activeSchedule[connection?.type]" class="flex flex-col gap-4 mt-4">
      <div>
        <label class="block mb-1">Frequency</label>
        <div class="flex items-center gap-3">
          <RadioInput
            label="Daily"
            :checked="schedule_frequency === 'daily'"
            class="w-1/3"
            @toggle="value => (schedule_frequency = value ? 'daily' : schedule_frequency)"
          />
          <RadioInput
            label="Weekly"
            :checked="schedule_frequency === 'weekly'"
            class="w-1/3"
            @toggle="value => (schedule_frequency = value ? 'weekly' : schedule_frequency)"
          />
          <RadioInput
            label="Monthly"
            :checked="schedule_frequency === 'monthly'"
            class="w-1/3"
            @toggle="value => (schedule_frequency = value ? 'monthly' : schedule_frequency)"
          />
        </div>
      </div>
      <div class="grid grid-cols-3 items-center gap-3">
        <div v-if="schedule_frequency === 'weekly'" class="col-span-1">
          <label class="block mb-1">Day of the week</label>
          <select
            v-model="schedule_day"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
          >
            <option value="0">Monday</option>
            <option value="1">Tuesday</option>
            <option value="2">Wednesday</option>
            <option value="3">Thursday</option>
            <option value="4">Friday</option>
            <option value="5">Saturday</option>
            <option value="6">Sunday</option>
          </select>
        </div>
        <div v-if="schedule_frequency === 'monthly'" class="col-span-1">
          <label class="block mb-1">Start date</label>
          <input
            v-model="schedule_start_date"
            type="date"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
          />
        </div>
        <div
          :class="{
            'col-span-2 col-start-2': schedule_frequency !== 'daily',
            'col-span-3': schedule_frequency === 'daily',
          }"
        >
          <div class="flex mb-1 justify-between items-center">
            <label class="block">
              Time of the day
              <span class="text-gray-500">(UTC)</span>
            </label>
            <p class="text-gray-500">Your time: {{ localTimeMessage }}</p>
          </div>
          <input
            v-model="schedule_time"
            type="time"
            class="schedule_time_input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
          />
        </div>
      </div>

      <div class="flex items-center space-x-2 dark:border-gray-700 justify-end gap-5">
        <button
          v-if="active_schedule?.job_id"
          type="button"
          class="text-red-600 hover:text-red-700 flex items-center gap-2 justify-center disabled:cursor-not-allowed"
          :disabled="removing_schedule"
          @click="deleteSchedule(active_schedule?.job_id)"
        >
          <LoadingIcon v-if="removing_schedule" class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin" />

          Delete Schedule
        </button>
        <button v-else type="button" class="cancel-btn" @click="activeSchedule[connection?.type] = false">
          Cancel
        </button>

        <button
          v-if="active_schedule?.job_id"
          type="button"
          class="primary-btn"
          :disabled="saving_schedule || !schedule_params_changed"
          @click="updateSchedule"
        >
          <LoadingIcon v-if="saving_schedule" class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin" />
          Update Schedule
        </button>
        <button
          v-else
          type="button"
          class="primary-btn flex items-center gap-2 justify-center disabled:cursor-not-allowed"
          :disabled="saving_schedule"
          @click="scheduleSync(false)"
        >
          <LoadingIcon v-if="saving_schedule" class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin" />
          Schedule
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, ref, watch } from 'vue'
  import axios from '@/axiosInstance'
  import RadioInput from './RadioInput.vue'
  import LoadingIcon from './icons/LoadingIcon.vue'
  import { useGlobalStore } from '@/stores/globalStore'

  export default {
    name: 'ScheduleSync',

    components: {
      RadioInput,
      LoadingIcon,
    },

    props: {
      connection: {
        type: Object,
        required: true,
      },
    },

    setup({ connection }) {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const activeSchedule = ref({
        snowflake: false,
        bigquery: false,
        redshift: false,
        postgres: false,
        databricks: false,
        mssql: false,
        looker: false,
        dbt: false,
      })

      const schedule_frequency = ref('daily')
      const schedule_day = ref(0)
      const schedule_start_date = ref(new Date().toISOString().slice(0, 10))
      const schedule_time = ref(new Date().toISOString().slice(11, 16))
      const active_schedule = ref(null)
      const saving_schedule = ref(false)
      const removing_schedule = ref(false)

      function resetSchedule() {
        schedule_frequency.value = 'daily'
        schedule_time.value = new Date().toISOString().slice(11, 16)
        schedule_day.value = 0
        schedule_start_date.value = new Date().toISOString().slice(0, 10)
      }

      const localTimeMessage = computed(() => {
        const utcDate = schedule_time.value ? new Date(`2000-01-01T${schedule_time.value}:00Z`) : new Date()

        const localDate = new Date(
          utcDate.toLocaleString('en-US', {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })
        )

        const localHours = String(localDate.getHours()).padStart(2, '0')
        const localMinutes = String(localDate.getMinutes()).padStart(2, '0')

        const timeZoneOffset = -localDate.getTimezoneOffset() / 60
        const timeZoneOffsetString = `UTC${timeZoneOffset >= 0 ? '+' : ''}${timeZoneOffset}`

        return `${localHours}:${localMinutes} (${timeZoneOffsetString})`
      })

      const schedule_params_changed = computed(() => {
        if (!active_schedule.value) {
          return true
        }

        let schedule_frequency_local = 'daily'
        let schedule_time_local = new Date().toISOString().slice(11, 16)
        let schedule_day_local = 0
        let schedule_start_date_local = new Date().toISOString().slice(0, 10)
        if (active_schedule.value.cron_args.day !== '*') {
          schedule_frequency_local = 'monthly'
          schedule_time_local = `${active_schedule.value.cron_args.hour}:${active_schedule.value.cron_args.minute}`
          const date = new Date()
          const year = date.getFullYear()
          let month = date.getMonth() + 1
          month = month < 10 ? '0' + month : month
          let day = active_schedule.value.cron_args.day
          day = day < 10 ? '0' + day : day
          schedule_start_date_local = `${year}-${month}-${day}`
        } else if (active_schedule.value.cron_args.day_of_week !== '*') {
          schedule_frequency_local = 'weekly'
          schedule_time_local = `${active_schedule.value.cron_args.hour}:${active_schedule.value.cron_args.minute}`
          schedule_day_local = active_schedule.value.cron_args.day_of_week
        } else {
          schedule_frequency_local = 'daily'
          schedule_time_local = `${active_schedule.value.cron_args.hour}:${active_schedule.value.cron_args.minute}`
        }

        return (
          schedule_frequency_local !== schedule_frequency.value ||
          schedule_time_local !== schedule_time.value ||
          schedule_start_date_local !== schedule_start_date.value ||
          schedule_day_local !== schedule_day.value
        )
      })

      watch(active_schedule, value => {
        if (value?.job_id) {
          setActiveSchedule(value)
        } else {
          resetSchedule()
        }
      })

      function setActiveSchedule(value) {
        if (!value || !value.cron_args) {
          resetSchedule()
          return
        }

        if (value.cron_args.day !== '*') {
          schedule_frequency.value = 'monthly'
          schedule_time.value = `${value.cron_args.hour}:${value.cron_args.minute}`
          const date = new Date()
          const year = value.cron_args.year !== '*' ? value.cron_args.year : date.getFullYear()
          let month = value.cron_args.month !== '*' ? value.cron_args.month : date.getMonth() + 1
          month = value.cron_args.month === '*' && month < 10 ? '0' + month : month
          let day = value.cron_args.day
          day = value.cron_args.day === '*' && day < 10 ? '0' + day : day
          schedule_start_date.value = `${year}-${month}-${day}`
        } else if (value.cron_args.day_of_week !== '*') {
          schedule_frequency.value = 'weekly'
          schedule_time.value = `${value.cron_args.hour}:${value.cron_args.minute}`
          schedule_day.value = value.cron_args.day_of_week
        } else {
          schedule_frequency.value = 'daily'
          schedule_time.value = `${value.cron_args.hour}:${value.cron_args.minute}`
        }
      }

      /**
       * Schedule Sync Function
       * @param {boolean} update - Indicates whether to update an existing schedule.
       */
      function scheduleSync(update = false) {
        let cron_args = {
          year: '*',
          month: '*',
          day: '*',
          week: '*',
          day_of_week: '*',
          hour: '*',
          minute: '*',
        }

        // Build cron_args based on the selected frequency
        if (schedule_frequency.value === 'daily') {
          cron_args.hour = schedule_time.value.split(':')[0]
          cron_args.minute = schedule_time.value.split(':')[1]
        } else if (schedule_frequency.value === 'weekly') {
          cron_args.day_of_week = schedule_day.value
          cron_args.hour = schedule_time.value.split(':')[0]
          cron_args.minute = schedule_time.value.split(':')[1]
        } else if (schedule_frequency.value === 'monthly') {
          cron_args.day = schedule_start_date.value.split('-')[2]
          cron_args.hour = schedule_time.value.split(':')[0]
          cron_args.minute = schedule_time.value.split(':')[1]
        }

        saving_schedule.value = true

        const body = {
          connection_type: connection.type,
          connection_id: connection.id,
          cron_args,
        }

        axios
          .post('/api/schedule_sync', body, { withCredentials: true })
          .then(() => {
            saving_schedule.value = false

            // Fetch updated schedule data
            fetchConnections()

            if (update) {
              notify.success('Schedule updated successfully')
            } else {
              notify.success('Sync scheduled successfully')
            }
          })
          .catch(error => {
            console.log(error)
            notify.error('Could not schedule sync')
            saving_schedule.value = false
          })
      }

      function deleteSchedule(job_id, update = false) {
        if (!job_id) {
          notify.error('Could not unschedule sync')
          return
        }

        removing_schedule.value = true
        axios
          .post('/api/remove_sync_job', { job_id, connection_id: connection.id }, { withCredentials: true })
          .then(() => {
            removing_schedule.value = false

            if (!update) {
              notify.success('Sync unscheduled successfully')
              active_schedule.value = null
              resetSchedule()
            }
          })
          .catch(error => {
            console.log(error)
            removing_schedule.value = false
            if (!update) {
              notify.error('Could not unschedule sync')
            }
          })
      }

      async function handleDeleteSchedule(job_id) {
        try {
          const res = await axios.post(
            '/api/remove_sync_job',
            { connection_id: connection.id, job_id },
            { withCredentials: true }
          )

          return res.data
        } catch (error) {
          console.log(error)
          return false
        }
      }

      function updateSchedule() {
        if (!schedule_params_changed.value) {
          return
        }

        handleDeleteSchedule(active_schedule.value.job_id).then(res => {
          if (res) {
            scheduleSync(true)
          } else {
            notify.error('Could not update schedule')
          }
        })
      }

      const fetchConnections = () => {
        axios.get(`/api/connections`, { withCredentials: true }).then(response => {
          if (response) {
            const currentConnection = response.data.find(conn => conn.id === connection.id)
            if (currentConnection && currentConnection.schedule?.job_id) {
              active_schedule.value = currentConnection.schedule

              activeSchedule.value[connection.type] = true
            }
          }
        })
      }
      fetchConnections()

      return {
        activeSchedule,
        schedule_frequency,
        schedule_day,
        schedule_start_date,
        schedule_time,
        localTimeMessage,
        active_schedule,
        saving_schedule,
        removing_schedule,
        scheduleSync,
        deleteSchedule,
        schedule_params_changed,
        updateSchedule,
      }
    },
  }
</script>
