<template>
  <svg
    :class="classList ? classList : 'w-6 h-6'"
    width="800px"
    height="800px"
    viewBox="-34 0 324 324"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMidYMid"
  >
    <g fill="#509EE3">
      <ellipse cx="19.3939396" cy="82.7565395" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="19.3939396" cy="137.927566" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="73.6969698" cy="82.7565395" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="73.6969698" cy="138.463513" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="128" cy="82.7565395" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="128" cy="19.703938" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="128" cy="138.463513" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="182.30303" cy="82.7565395" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="236.60606" cy="82.7565395" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="182.30303" cy="138.463513" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="236.60606" cy="138.463513" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="19.3939396" cy="193.098592" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="73.6969698" cy="193.634539" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="128" cy="193.634539" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="182.30303" cy="193.634539" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="236.60606" cy="193.634539" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="19.3939396" cy="248.269618" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="73.6969698" cy="248.805565" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="128" cy="248.805565" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="128" cy="303.976591" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse opacity="0.2" cx="182.30303" cy="248.805565" rx="19.3939394" ry="19.703938"></ellipse>
      <ellipse cx="236.60606" cy="248.805565" rx="19.3939394" ry="19.703938"></ellipse>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'MetabaseIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
