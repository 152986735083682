<template>
  <div
    class="fixed inset-0 z-[39] flex bg-[rgba(0,0,0,0.5)] dark:bg-[#313131B2] transition-opacity duration-100 ease-linear cursor-pointer"
    :class="{
      'opacity-100 pointer-events-auto': show,
      'opacity-0 pointer-events-none': !show,
    }"
    role="dialog"
    aria-hidden="true"
  >
    <!-- Transition component wrapping the modal content -->
    <transition
      name="slide-modal"
      enter-active-class="transition-transform duration-150 ease-out"
      leave-active-class="transition-transform duration-150 ease-in"
      enter-from-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-from-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="show"
        v-click-away="emitClose"
        class="absolute h-full max-h-full bg-white dark:bg-gray-950 text-black dark:text-white right-0 w-full max-w-[517px]"
        role="dialog"
        aria-hidden="true"
      >
        <!-- Modal header and content -->
        <div class="w-full border-b primary-border p-6 flex items-center justify-between">
          <slot name="title" />
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white shrink-0"
            @click.stop="emitClose"
          >
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div class="p-6 overflow-y-auto">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'SidebarModal',
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      className: {
        type: String,
        default: '',
      },
    },
    emits: ['close'],
    methods: {
      emitClose() {
        this.$emit('close')
      },
    },
  }
</script>

<style scoped>
  /* Add transition classes for the modal sliding effect */
  .slide-modal-enter-active,
  .slide-modal-leave-active {
    transition: transform 0.15s ease-in-out;
  }

  .slide-modal-enter,
  .slide-modal-leave-to {
    transform: translateX(100%);
  }
</style>
