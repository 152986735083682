<template>
  <div>
    <router-link
      :to="{
        query: {
          ...route.query,
          hash: commit.hash,
        },
      }"
      class="px-4 py-3 flex items-center justify-between borde primary-border dark:bg-gray-950 group cursor-pointer"
      @click="handleOpenCommit"
    >
      <div class="flex flex-col gap-1">
        <p class="font-medium text-base group-hover:text-blue-500 duration-200 ease-linear transition-colors text-left">
          {{ commit.message }}
        </p>
        <div class="flex items-center gap-2 text-12">
          <span class="text-gray-500">{{ changed_file }}</span>
          <span class="bg-gray-500 size-1 rounded-full" />
          <span class="flex items-center gap-1">
            <span class="text-gray-500">{{ commit.author_name || commit.author_email }} authored</span>
            <span v-tooltip="{ content: commit.date, placement: 'top' }" class="text-gray-500">
              {{ commit.formattedDate }}
            </span>
          </span>
        </div>
      </div>
      <div class="flex items-center gap-2">
        <span class="text-sm text-gray-500">{{ commit.id }}</span>
        <button
          v-tooltip="{ content: copied ? 'Copied!' : 'Copy link', placement: 'left' }"
          class="text-sm text-blue-500"
          @click.stop.prevent="handleCopyCommitLink"
        >
          <Icon v-if="!copied" icon="si:copy-line" class="w-4 h-4" />
          <Icon v-else icon="mingcute:check-line" class="w-4 h-4 text-green-500" />
        </button>
      </div>
    </router-link>

    <!-- DrawerModal usage -->
    <DrawerModal :show="show_commit_diff && !!commit.hash" @close="closeDrawer">
      <template #title>
        <div class="flex items-center gap-4">
          <div class="flex items-center gap-4 border-r primary-border pr-4">
            <h2 class="text-24">Commit</h2>
            <p v-tooltip="commit.hash" class="text-14 font-medium">
              {{ formatHash(commit.hash) }}
            </p>
          </div>

          <div class="flex items-center gap-2 text-14 px-4 py-2 bg-[#F6F8FA] dark:bg-gray-900 h-10 rounded-8">
            <span class="font-semibold">{{ commit.author_name || commit.author_email }}</span>
            <span class="text-gray-500">authored</span>
            <span v-tooltip="{ content: commit.date, placement: 'top' }" class="text-gray-500">
              {{ commit.formattedDate }}
            </span>
          </div>
        </div>
      </template>
      <div class="px-6">
        <div class="p-2 border primary-border flex items-center justify-between rounded-12 h-11 mb-2">
          <p class="text-14">{{ commit.message }}</p>
          <button
            v-tooltip="{ content: copied ? 'Copied!' : 'Copy link', placement: 'left' }"
            class="text-sm text-blue-500"
            @click.stop.prevent="handleCopyCommitLink"
          >
            <Icon v-if="!copied" icon="si:copy-line" class="w-4 h-4" />
            <Icon v-else icon="mingcute:check-line" class="w-4 h-4 text-green-500" />
          </button>
        </div>
        <div class="h-[90vh] overflow-y-auto no-scrollbar pb-20">
          <CommitDiff />
        </div>
      </div>
    </DrawerModal>
  </div>
</template>

<script>
  import { computed, ref, watch } from 'vue'
  import DrawerModal from './DrawerModal.vue'
  import CommitDiff from '@/CommitDiff.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { formatHash } from '@/hooks/formatNumber'

  export default {
    name: 'Commit',
    components: {
      DrawerModal,
      CommitDiff,
    },
    props: {
      commit: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const router = useRouter()
      const route = useRoute()

      // states
      const show_commit_diff = ref(false)
      const copied = ref(false)

      const handleOpenCommit = () => {
        show_commit_diff.value = true
      }

      const handleCopyCommitLink = () => {
        let url = window.location.href

        // add hash if not present
        if (!route.query.hash) {
          // check if any query params are present
          url += url.includes('?') ? `&hash=${props.commit.hash}` : `?hash=${props.commit.hash}`
        }

        navigator.clipboard.writeText(url)

        copied.value = true
        setTimeout(() => {
          copied.value = false
        }, 2000)
      }

      const closeDrawer = () => {
        show_commit_diff.value = false

        // remove the only the hash query from the URL
        // eslint-disable-next-line no-unused-vars
        const { hash, ...query } = route.query
        router.push({ query })
      }

      watch(
        () => route,
        val => {
          if (!val?.query?.hash || show_commit_diff.value) {
            return
          }

          show_commit_diff.value = val.query.hash === props.commit.hash
        },
        { immediate: true }
      )

      // get changed files
      const changed_file = computed(() => {
        const files = props.commit.files_changed
        const count = files.length

        if (count === 1) {
          // return the first file name
          return files[0]
        }

        return `${files[0]} and ${count - 1} other file${count > 2 ? 's' : ''}`
      })

      return {
        handleOpenCommit,
        handleCopyCommitLink,
        copied,
        show_commit_diff,
        closeDrawer,
        formatHash,
        changed_file,
        route,
      }
    },
  }
</script>
