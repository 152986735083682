<template>
  <section class="bg-white dark:bg-[#141414] w-full min-h-screen text-gray-900 dark:text-white">
    <div class="px-6 pb-10 sm:py-8 w-full gap-10 flex flex-col justify-center h-screen">
      <div class="w-full flex justify-center items-center flex-col lg:flex-row gap-0 md:gap-10 lg:gap-[112px]">
        <div class="w-full max-w-[420px]">
          <div class="flex flex-col items-center gap-6 mb-10">
            <router-link to="/" class="flex items-center text-2xl font-semibold">
              <img alt="Dot" class="w-8 md:w-20 rounded-full mx-auto" src="./assets/fox_avatar_7.jpg" />
            </router-link>
            <h1 class="text-xl font-bold md:text-2xl">Chat with Dot</h1>
          </div>
          <div class="space-y-10 hidden md:block">
            <div class="flex gap-4">
              <Icon class="text-primary w-8 h-8 shrink-0" icon="hugeicons:chatting-01" />
              <div class="">
                <h2 class="text-base font-semibold">Free for 100 messages per month</h2>
                <p class="text-gray-500 flex gap-2 font-normal text-sm">
                  Answers data questions accurately. Track your most important metrics. Carry deep dive analysis.
                </p>
              </div>
            </div>
            <div class="flex gap-4">
              <Icon class="text-primary w-8 h-8 shrink-0" icon="hugeicons:chart-histogram" />
              <div class="flex-initial">
                <h2 class="text-base font-semibold">Start uncovering insights immediately</h2>
                <p class="text-gray-500 flex gap-2 font-normal text-sm">
                  Leverage your cloud data warehouse, BI tool, or semantic layer easily with no-code integrations.
                </p>
              </div>
            </div>
            <div class="flex gap-4">
              <Icon class="text-primary w-8 h-8 shrink-0" icon="hugeicons:crown" />
              <div class="flex-initial">
                <h2 class="text-base font-semibold">Join industry leaders that ask Dot</h2>
                <p class="text-gray-500 flex gap-2 font-normal text-sm">
                  Airbyte, Babbel, Flix, GLS, Virgin, and many more.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full rounded-24 border primary-border max-w-[510px] px-4 py-8 md:px-8 md:py-10">
          <div class="space-y-4 md:space-y-6">
            <h1 class="text-24 font-bold text-center">Create an account</h1>
            <form class="space-y-4 md:space-y-6" action="#">
              <div>
                <label for="realname" class="primary-label">Your Name</label>
                <input
                  id="realname"
                  v-model="realname"
                  type="text"
                  name="realname"
                  class="primary-input"
                  placeholder="Claude Shannon"
                  required=""
                />
              </div>
              <div>
                <label for="email" class="primary-label">Company email</label>
                <input
                  id="email"
                  v-model="username"
                  type="email"
                  name="email"
                  class="primary-input"
                  placeholder="claude.shannon@bell-labs.com"
                  required=""
                />
              </div>
              <div>
                <label for="password" class="primary-label">Password</label>
                <div class="relative">
                  <input
                    id="password"
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    placeholder="••••••••"
                    class="primary-input"
                    required=""
                    @input="validatePassword"
                  />

                  <button
                    v-tooltip="showPassword ? 'Hide password' : 'Show password'"
                    type="button"
                    class="absolute inset-y-0 right-0 flex items-center pr-3"
                    @click="showPassword = !showPassword"
                  >
                    <Icon
                      class="size-4 text-gray-400 dark:text-gray-500"
                      :icon="showPassword ? 'hugeicons:eye' : 'uil:eye-slash'"
                    />
                  </button>
                </div>
                <!-- Display password validation error message -->
                <p v-if="passwordError" class="text-sm text-red-500 mt-1">
                  {{ passwordError }}
                </p>
              </div>
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="terms"
                    v-model="terms"
                    aria-describedby="terms"
                    type="checkbox"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-800 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    required=""
                    data-test="9cb2c077-deec-4493-a34e-56802f6c499a"
                  />
                </div>
                <div class="ml-3 text-sm">
                  <label for="terms" class="font-light text-gray-500 dark:text-gray-300">
                    I accept the
                    <a
                      class="font-medium text-blue-600 hover:underline dark:text-blue-500"
                      href="https://uploads-ssl.webflow.com/644a347ea803e322d9c0feb8/647748918f17ddf6429d9045_Dot_FreeTrial_MSA_May23.doc.pdf"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
              <button
                id="create_account"
                class="primary-btn !w-full !font-medium"
                :disabled="creating_account"
                @click.prevent="register"
              >
                <LoadingIcon v-if="creating_account" class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin" />
                Create an account
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400 text-center mt-8">
                Already have an account?
                <router-link to="/login" class="font-medium text-blue-600 hover:underline dark:text-blue-500">
                  Login here
                </router-link>
                <br />
                <!-- show the following if the url does not start with eu.getdot.ai -->
                <span
                  v-if="hostname !== 'eu.getdot.ai'"
                  class="text-sm mt-0 font-light text-gray-500 dark:text-gray-400"
                >
                  Region: US
                  <a
                    :href="`https://eu.getdot.ai/register?${gclid ? `gclid=${gclid}` : ''}`"
                    class="ml-1 font-medium text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Switch to EU
                  </a>
                </span>
                <span v-else class="text-sm mt-0 font-light text-gray-500 dark:text-gray-400">
                  Region: EU
                  <a
                    :href="`https://app.getdot.ai/register?${gclid ? `gclid=${gclid}` : ''}`"
                    class="ml-1 font-medium text-blue-600 hover:underline dark:text-blue-500"
                  >
                    Switch to US
                  </a>
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import { onMounted, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import axios from 'axios'
  import LoadingIcon from './components/icons/LoadingIcon.vue'
  import { useGlobalStore } from './stores/globalStore'

  const router = useRouter()

  const globalStore = useGlobalStore()
  const notify = globalStore.notify

  const gclid = new URLSearchParams(window.location.search).get('gclid')
  const hostname = window.location.hostname
  const creating_account = ref(false)

  // Form fields
  const username = ref('')
  const password = ref('')
  const realname = ref('')
  const terms = ref(false)
  const showPassword = ref(false)

  // Password error message
  const passwordError = ref('')

  // Validate email
  const validateEmail = email => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return regex.test(email)
  }

  /**
   * Validates the password in real-time as the user types.
   * Requirements:
   * - At least 8 characters long
   * - Contains at least one uppercase letter, one lowercase letter,
   *   one digit, and one special character.
   */
  const validatePassword = () => {
    const pw = password.value

    // Construct the pattern
    const uppercase = /[A-Z]/
    const lowercase = /[a-z]/
    const digit = /\d/
    // eslint-disable-next-line no-useless-escape
    const specialChar = /[\[\]@#$!%*?&^()_\-+={};:"'<>,.~]/

    if (pw.length < 8) {
      passwordError.value = 'Password must be at least 8 characters long.'
      return
    }
    if (!uppercase.test(pw)) {
      passwordError.value = 'Password must contain at least one uppercase letter.'
      return
    }
    if (!lowercase.test(pw)) {
      passwordError.value = 'Password must contain at least one lowercase letter.'
      return
    }
    if (!digit.test(pw)) {
      passwordError.value = 'Password must contain at least one number.'
      return
    }
    if (!specialChar.test(pw)) {
      passwordError.value = 'Password must contain at least one special character (e.g., @, #, $).'
      return
    }

    // If all checks pass, clear the error
    passwordError.value = ''
  }

  const register = async () => {
    // Basic client-side checks
    if (username.value === '' || password.value === '' || realname.value === '' || terms.value === false) {
      notify.error('Please fill in all fields and accept Terms and Conditions.')
      return
    }

    if (!validateEmail(username.value)) {
      notify.error('Please enter a valid email address.')
      return
    }

    // If there's any password validation error, stop
    if (passwordError.value !== '') {
      notify.error(passwordError.value)
      return
    }

    // For university emails, send them to this page https://forms.gle/zjeVkFigzmX1pXDv5
    if (username.value.includes('.edu')) {
      window.open('https://forms.gle/zjeVkFigzmX1pXDv5', '_blank')
    }

    try {
      creating_account.value = true
      const response = await axios.post(
        '/api/auth/register',
        {
          username: username.value,
          password: password.value,
          realname: realname.value,
          gclid,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )

      if (response.status === 200 || response.status === 201) {
        console.log('register successful')
        localStorage.setItem('username', username.value)
        router.push('/verify')
      }
    } catch (error) {
      console.log(username.value)
      console.log(error)

      if (typeof error?.response?.data?.detail === 'string') {
        notify.error(error?.response?.data?.detail)
      } else if (Array.isArray(error?.response?.data?.detail)) {
        notify.error(error?.response?.data?.detail[0]?.msg)
      } else {
        notify.error('An error occurred. Please try again.')
      }
      console.log('registration failed')
    } finally {
      creating_account.value = false
    }
  }

  onMounted(() => {})
</script>
