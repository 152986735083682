<template>
  <div ref="chartContainer" class="px-4">
    <div v-if="message.role === 'user'" class="flex items-center font-bold mt-8">
      <VMdPreview
        :text="message.content"
        class="whitespace-pre-wrap text-base p-1 w-full focus:outline-none overflow-y-auto"
      />
    </div>

    <div v-if="message.formatted_result">
      <div v-for="(result, message_index) in message.formatted_result" :key="message_index">
        <div v-if="result.type === 'text'" :id="'text' + message_index" class="text-section">
          <VMdPreview :text="result.content" class="pre-wrap text-base p-1 w-full focus:outline-none overflow-y-auto" />
        </div>

        <div id="myTabContent">
          <div
            v-if="result.type === 'chart'"
            :id="'chart' + message_index"
            aria-labelledby="chart-tab"
            role="tabpanel"
            class="chart_grand_parent will-change-transform"
          >
            <div class="bg-white dark:bg-gray-900 rounded-xl p-2 overflow-y-auto max-h-[600px]">
              <div
                v-if="message.interpretation"
                v-dompurify-html="message.interpretation.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')"
                class="pb-2.5 text-base leading-relaxed whitespace-pre-line"
              />
              <!-- If there are any found_external_assets, list them with their title and they should link to the url -->
              <div v-if="message.found_external_assets && message.found_external_assets.length" class="pb-2.5">
                Related dashboards:&nbsp;
                <span v-for="(asset, index) in message.found_external_assets" :key="index">
                  <a
                    :href="asset.url ? asset.connection_id.replace(/\/+$/, '') + asset.url : asset.external_url"
                    target="_blank"
                    class="externalLink"
                  >
                    {{ asset.name }}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-external-link"
                    >
                      <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                      <polyline points="15 3 21 3 21 9" />
                      <line x1="10" y1="14" x2="21" y2="3" />
                    </svg>
                  </a>
                  <span v-if="index < message.found_external_assets.length - 1">,</span>
                </span>
              </div>
              <!-- Lazy load the chart -->
              <div v-if="isChartVisible">
                <!-- render Plotly chart -->
                <PlotlyChart v-if="result.json_chart" :chart-config="result.json_chart" />
              </div>

              <!-- Loader -->
              <div v-else class="flex items-center justify-center h-40">
                <LoadingIcon class="w-5 h-5 text-gray-500 dark:text-gray-300 animate-spin" />
              </div>

              <h3
                v-if="message.filter_text && message.filter_text !== 'No filters applied.'"
                class="cursor-pointer text-gray-400 dark:text-gray-500 mt-2 flex items-center gap-2 hover:dark:text-yellow-400 hover:text-yellow-500"
                @click="toggleShowFullLogs(message_index)"
              >
                <!-- count number of new lines to count filters  -->
                {{ message.filter_text.split('\n').length }} Filter{{
                  message.filter_text.split('\n').length > 1 ? 's' : ''
                }}
                <Icon
                  :icon="show_full_logs[message_index] ? 'flowbite:angle-up-outline' : 'flowbite:angle-down-outline'"
                  class="w-4 h-4"
                />
              </h3>
              <div
                v-if="message.filter_text"
                v-dompurify-html="message.filter_text"
                class="whitespace-pre-line leading-relaxed overflow-scroll transition-max-height duration-300 ease-in-out"
                :class="{
                  'max-h-[100px]': show_full_logs[message_index],
                  'max-h-[0]': !show_full_logs[message_index],
                }"
              />
            </div>
          </div>

          <div v-if="result.type === 'data'" :id="'data' + message_index" aria-labelledby="data-tab" role="tabpanel">
            <div>data</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, nextTick, onMounted, ref } from 'vue'
  import PlotlyChart from './PlotlyChart.vue'
  import LoadingIcon from './icons/LoadingIcon.vue'
  import VMdPreview from '@kangc/v-md-editor/lib/preview'
  import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
  import hljs from 'highlight.js'
  import { initDropdowns, initModals, initTabs } from 'flowbite'

  VMdPreview.use(vuepressTheme, { Hljs: hljs })

  export default defineComponent({
    name: 'Report',

    components: {
      PlotlyChart,
      LoadingIcon,
      VMdPreview,
    },

    props: {
      message: {
        type: Object,
        required: true,
      },
      shared_page: {
        type: Boolean,
        required: true,
      },
      is_external_chat: {
        type: Boolean,
        required: true,
      },
      shared_or_external: {
        type: Boolean,
        required: true,
      },
      response_loading: {
        type: Boolean,
        required: true,
      },
      currentChatId: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      console.log(props.message, 'props')

      const chartContainer = ref(null) // Reference to the chart container
      const isChartVisible = ref(false) // Track if the chart is visible

      function reloadTabsAndDropdowns() {
        nextTick(() => {
          initTabs()
          initDropdowns()
          initModals()
        })
      }

      reloadTabsAndDropdowns()

      onMounted(() => {
        initTabs()
        initDropdowns()
        initModals()

        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              isChartVisible.value = true

              nextTick(() => {
                reloadTabsAndDropdowns()
              })

              observer.disconnect() // Stop observing once the chart is visible
            }
          })
        })

        if (chartContainer.value) {
          observer.observe(chartContainer.value)
        }
      })

      return {
        isChartVisible,
        chartContainer,
      }
    },
  })
</script>

<style scoped>
  .section {
    @apply rounded-xl;
  }

  .text-section {
    @apply text-base leading-relaxed;
  }

  .chart-section {
    @apply will-change-transform;
  }
</style>
