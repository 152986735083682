<template>
  <div
    v-click-away="() => (isOpen = false)"
    class="relative"
    :class="{
      'opacity-50': props.disabled,
    }"
  >
    <!-- Dropdown Button Own Columns -->
    <button
      :id="`${id}-dropdownButton`"
      v-tooltip="selectedValue"
      class="primary-input text-left truncate flex items-center gap-2 max-w-60"
      :class="props.buttonClass"
      :disabled="props.disabled"
      type="button"
      @click="toggleDropdown"
    >
      <slot name="icon" />
      <span class="truncate flex items-center gap-1">
        <span>{{ selectedValue }}</span>
        <span v-if="selectedValueLabel" class="text-[#808080] dark:text-[#B0B0B0] font-mono">
          {{ typeof selectedValueLabel === 'function' ? selectedValueLabel() : selectedValueLabel }}
        </span>
      </span>

      <Icon :icon="isOpen ? 'heroicons:chevron-up' : 'heroicons:chevron-down'" class="w-4 h-4 ml-auto shrink-0" />
    </button>
    <!-- Dropdown Menu -->
    <div
      v-show="isOpen"
      class="z-20 bg-white rounded-xl shadow w-full min-w-60 dark:bg-gray-800"
      :class="{
        'absolute top-[calc(100%+10px)]': !props.usedInTable,
        'right-0': props.alignDropdown === 'right',
        'left-0': props.alignDropdown === 'left',
        fixed: props.usedInTable,
        [props.dropdownClass]: props.dropdownClass,
      }"
      :style="{
        top: props.usedInTable ? `${dropdownPosition.top}px` : undefined,
        left: props.usedInTable ? `${dropdownPosition.left}px` : undefined,
      }"
    >
      <div class="p-1 pb-0">
        <Search v-model="search" placeholder="Search.." class="!p-2 !w-full" />
      </div>
      <ul
        class="h-48 px-2 pb-2 overflow-y-auto text-sm text-gray-800 dark:text-gray-200"
        :aria-labelledby="`${id}-dropdownButton`"
      >
        <li
          v-for="(option, index) in displayedOptions"
          :key="option.value"
          v-tooltip="option.label"
          @click="() => handleSelectOption(option.value)"
        >
          <div
            :id="`${id}-option-${index}`"
            class="flex items-center p-1 rounded hover:bg-gray-100 dark:hover:bg-gray-700 gap-1"
          >
            <input
              v-if="props.isMultiple"
              type="checkbox"
              :checked="selectedValues.includes(option.value)"
              class="w-4 h-4 text-blue-600 bg-gray-100 cursor-pointer border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              class="block w-max max-w-[300px] md:max-w-[500px] lg:max-w-[unset] py-1 cursor-pointer text-xs font-medium text-gray-900 rounded dark:text-gray-300"
            >
              {{ option.label }}

              <span v-if="option.sub_label" class="text-[#808080] dark:text-[#B0B0B0] font-mono">
                {{ option.sub_label }}
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue'

  // Define the events that the component can emit
  const emit = defineEmits(['select'])

  const props = defineProps({
    id: {
      type: String,
      required: true,
    },
    options: {
      // array of object {label: string, value: string, sub_label: string}
      type: Array,
      required: true,
    },
    selectedValue: {
      type: String,
      required: true,
    },
    selectedValueLabel: {
      type: [String, Function],
      required: false,
      default: '',
    },
    alignDropdown: {
      type: String,
      required: false,
      default: 'left',
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedValues: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    usedInTable: {
      type: Boolean,
      required: false,
      default: true,
    },
    dropdownClass: {
      type: String,
      required: false,
      default: '',
    },
    buttonClass: {
      type: String,
      required: false,
      default: '',
    },
  })

  const isOpen = ref(false)
  const search = ref('')
  const dropdownPosition = ref({
    top: 0,
    left: 0,
  })

  const toggleDropdown = $event => {
    isOpen.value = !isOpen.value

    setDropdownPosition($event)
  }

  const setDropdownPosition = event => {
    try {
      // Get the position of the clicked button
      let buttonRect = event?.target?.closest('button')

      if (!buttonRect) {
        buttonRect = event?.target?.getBoundingClientRect()
      } else {
        buttonRect = buttonRect.getBoundingClientRect()
      }

      if (buttonRect) {
        dropdownPosition.value = {
          left: !props.alignDropdownLeft ? buttonRect.x - 50 : buttonRect.x,
          top: buttonRect.y + buttonRect.height,
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectOption = value => {
    emit('select', value)
    isOpen.value = props.isMultiple ? true : false
  }

  const displayedOptions = computed(() => {
    let result = [...props.options]

    if (search.value) {
      result = result.filter(
        option =>
          option.label.toLowerCase().includes(search.value.toLowerCase()) ||
          option.value.toLowerCase().includes(search.value.toLowerCase())
      )
    }

    // if it's multiple, sort by selected first
    if (props.isMultiple) {
      result = result.sort(a => {
        return props.selectedValues.includes(a.value) ? -1 : 1
      })
    }

    return result
  })
</script>
