<!-- Settings Drawer -->
<template>
  <div
    class="container px-[24px] pb-8 overflow-y-scroll bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full"
  >
    <div class="tab-wrapper">
      <router-link
        id="connection-tab"
        to="/model"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'model',
          'inactive-tab': activeTab !== 'model',
        }"
      >
        Model
      </router-link>
      <router-link
        to="/skills"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'skills',
          'inactive-tab': activeTab !== 'skills',
        }"
      >
        Skills
      </router-link>
      <router-link
        to="/evaluation"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'evaluation',
          'inactive-tab': activeTab !== 'evaluation',
        }"
      >
        Evaluation
      </router-link>
      <router-link
        to="/external-assets"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'external-assets',
          'inactive-tab': activeTab !== 'external-assets',
        }"
      >
        External Assets
      </router-link>
      <router-link
        to="/version-history"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'version-history',
          'inactive-tab': activeTab !== 'version-history',
        }"
      >
        Version History
      </router-link>
    </div>
    <div id="myTabContent">
      <ModelTab v-if="activeTab === 'model'" />
      <EvaluationTab v-else-if="activeTab === 'evaluation'" />
      <ExternalAssetTab v-else-if="activeTab === 'external-assets'" />
      <VersionHistoryPage v-else-if="activeTab === 'version-history'" />
      <ModulesTab v-else-if="activeTab === 'skills' || activeTab === 'modules'" />
    </div>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router'
  import { useGlobalStore } from './stores/globalStore'
  import ModelTab from '@/components/ModelTab.vue'
  import EvaluationTab from '@/components/EvaluationTab.vue'
  import ExternalAssetTab from '@/components/ExternalAssetTab.vue'
  import VersionHistoryPage from './VersionHistoryPage.vue'
  import ModulesTab from './components/ModulesTab.vue'

  export default {
    name: 'ModelEvalPage',
    components: {
      ModelTab,
      EvaluationTab,
      ExternalAssetTab,
      VersionHistoryPage,
      ModulesTab,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify
      const router = useRouter()

      const changeTab = tab => {
        router.push(`/${tab}`)
      }

      return {
        changeTab,
        notify,
      }
    },

    computed: {
      activeTab() {
        if (
          this.$route.path.replace('/', '') === 'evaluation/question' ||
          this.$route.path.replace('/', '') === 'evaluation/timestamp'
        ) {
          return 'evaluation'
        }
        return this.$route.path.replace('/', '')
      },
    },
  }
</script>
