<template>
  <div v-if="loading || (diffFiles.length === 0 && rawDiffFiles.length === 0)" class="flex gap-1 h-full">
    <SkeletonLoader class="h-full" />
    <SkeletonLoader class="h-full" />
  </div>
  <div v-else-if="!isMultipleFiles" class="space-y-8">
    <!-- Loop through each file and render a diff view -->
    <div v-for="(diffItem, index) in diffFiles" :key="index" class="file-diff" style="margin-bottom: 2rem">
      <h2 class="px-2 py-1 bg-[#F6F8FA] dark:bg-gray-900 rounded-t-[12px]">{{ diffItem.fileName }}</h2>
      <DiffView
        :diff-file="diffItem.file"
        :font-size="14"
        :highlight="true"
        :add-widget="true"
        :wrap="true"
        :theme="userStore.isDarkMode ? 'dark' : 'light'"
      />
    </div>
  </div>
  <div v-else class="space-y-4">
    <div v-for="(diffItem, index) in rawDiffFiles" :key="index" class="">
      <button
        type="button"
        class="p-2 w-full bg-[#F6F8FA] dark:bg-gray-900 flex items-center justify-between hover:bg-gray-100 dark:hover:bg-gray-800"
        :class="{
          'rounded-t-[12px]': diffItem.expanded,
          'rounded-12': !diffItem.expanded,
        }"
        @click="handleRawDiffFileClick(diffItem.fileName)"
      >
        {{ diffItem.fileName }}

        <Icon :icon="diffItem.expanded ? 'tdesign:chevron-up' : 'tdesign:chevron-down'" class="w-4 h-4" />
      </button>
      <div
        class="bg-white dark:bg-gray-900 transition-[max-height] duration-300 ease-linear"
        :class="{
          'max-h-0 overflow-hidden': !diffItem.expanded,
          'max-h-auto': diffItem.expanded,
        }"
      >
        <div v-if="!diffItem.diff" class="flex gap-1 h-[300px]">
          <SkeletonLoader class="h-full" />
          <SkeletonLoader class="h-full" />
        </div>
        <DiffView
          v-else
          :diff-file="diffItem.diff"
          :font-size="14"
          :highlight="true"
          :add-widget="true"
          :wrap="true"
          :theme="userStore.isDarkMode ? 'dark' : 'light'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, watch } from 'vue'
  import axios from '@/axiosInstance'
  import { useRoute } from 'vue-router'
  import { DiffView } from '@git-diff-view/vue'
  import '@git-diff-view/vue/styles/diff-view.css'
  import { generateDiffFile } from '@git-diff-view/file'
  import { useUserStore } from './stores/userStore'
  import SkeletonLoader from './components/SkeletonLoader.vue'

  const route = useRoute()
  const userStore = useUserStore()

  // states
  const loading = ref(false)
  const commit_id = computed(() => route.query.hash)
  const diffFiles = ref([]) // Array to hold diff info for all files
  const rawDiffFiles = ref([])
  const isMultipleFiles = ref(false)
  const changesResponse = ref({})

  const fetchCommit = async () => {
    // return if commit_id is not available
    if (!commit_id.value) return

    try {
      loading.value = true
      const response = await axios.get('/api/git_commit_changes', {
        params: { commit_id: commit_id.value },
      })

      if (response && response.data && response.data.changes) {
        const changes = response.data.changes
        changesResponse.value = changes

        // Map through all changed files and generate diffFiles array
        const limitedFiles = Object.keys(changes).slice(0, 15) // Limit to 15 files

        // if files are more than 1, return
        if (limitedFiles.length > 1) {
          isMultipleFiles.value = true
          rawDiffFiles.value = limitedFiles.map(docName => {
            return {
              fileName: docName,
              diff: null,
              expanded: false,
            }
          })
          loading.value = false
          return
        }

        diffFiles.value = limitedFiles.map(docName => {
          const oldContent = changes[docName].old_content || ''
          const newContent = changes[docName].new_content || ''

          const file = generateDiffFile(
            changes[docName].doc_id || 'old_file',
            oldContent,
            changes[docName].doc_id || 'new_file',
            newContent,
            '', // old file language (optional)
            '' // new file language (optional)
          )

          file.initTheme(userStore.isDarkMode ? 'dark' : 'light')
          file.init()
          file.buildSplitDiffLines()
          file.buildUnifiedDiffLines()

          return {
            fileName: docName,
            file,
          }
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const handleRawDiffFileClick = fileName => {
    rawDiffFiles.value = rawDiffFiles.value.map(item => {
      if (item.fileName === fileName) {
        // toggle the expanded state
        item.expanded = !item.expanded

        // generate the diff file if it is not already generated
        if (!item.diff) {
          item.diff = generateDiffFile(
            changesResponse.value[item.fileName].doc_id || 'old_file',
            changesResponse.value[item.fileName].old_content || '',
            changesResponse.value[item.fileName].doc_id || 'new_file',
            changesResponse.value[item.fileName].new_content || '',
            '', // old file language (optional)
            '' // new file language (optional)
          )
          item.diff.initTheme(userStore.isDarkMode ? 'dark' : 'light')
          item.diff.init()
          item.diff.buildSplitDiffLines()
          item.diff.buildUnifiedDiffLines()
        }
      } else {
        item.expanded = false
      }

      return item
    })
  }

  watch(commit_id, (newVal, oldVal) => {
    if (newVal !== oldVal) {
      fetchCommit()
    }
  })

  onMounted(() => {
    fetchCommit()
  })
</script>
