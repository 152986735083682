<template>
  <svg
    id="svg1"
    :class="classList ? classList : 'w-6 h-6'"
    width="45.810001mm"
    height="44.879002mm"
    viewBox="0 0 45.810001 44.879002"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs1" />
    <g id="layer1">
      <g id="g9" transform="scale(0.41341146)">
        <polygon
          id="polygon1"
          fill="#7099a6"
          points="57.166378,23.370418 57.166378,13.334042 66.403941,13.334042 66.403941,10.056858 57.166378,10.056858 57.166378,0 53.643406,0 53.643406,5.018188 53.643406,10.056858 44.63115,10.056858 44.63115,13.334042 53.643406,13.334042 53.643406,23.370418 "
        />
        <polygon
          id="polygon2"
          fill="#eb912c"
          points="29.248867,44.54922 29.248867,29.69948 43.09497,29.69948 43.09497,25.111422 29.248867,25.111422 29.248867,10.486989 24.189714,10.486989 24.189714,17.799206 24.189714,25.111422 10.548436,25.111422 10.548436,29.69948 24.189714,29.69948 24.189714,44.54922 "
        />
        <polygon
          id="polygon3"
          fill="#59879b"
          points="86.62007,44.54922 86.62007,29.924786 100.26135,29.924786 100.26135,25.111422 86.62007,25.111422 86.62007,10.486989 81.356093,10.486989 81.356093,17.799206 81.356093,25.111422 67.714814,25.111422 67.714814,29.924786 81.356093,29.924786 81.356093,44.54922 "
        />
        <polygon
          id="polygon4"
          fill="#e8762c"
          points="58.477252,73.388439 58.477252,57.002519 73.65471,57.002519 73.65471,50.898764 58.477252,50.898764 58.477252,34.512844 52.332532,34.512844 52.332532,42.705804 52.332532,50.898764 37.155074,50.898764 37.155074,57.002519 52.332532,57.002519 52.332532,73.388439 "
        />
        <polygon
          id="polygon5"
          fill="#5b6591"
          points="101.57222,66.403941 101.57222,56.142258 110.80978,56.142258 110.80978,51.984331 101.57222,51.984331 101.57222,41.722649 96.963682,41.722649 96.963682,46.843249 96.963682,51.984331 87.726119,51.984331 87.726119,56.142258 96.963682,56.142258 96.963682,66.403941 "
        />
        <polygon
          id="polygon6"
          fill="#7099a6"
          points="12.535229,65.523198 12.535229,55.691646 21.772791,55.363927 21.772791,52.209638 12.535229,52.209638 12.535229,42.378086 9.012256,42.378086 9.012256,47.293862 9.012256,52.209638 0,52.209638 0,55.691646 9.012256,55.691646 9.012256,65.523198 "
        />
        <polygon
          id="polygon7"
          fill="#c72035"
          points="29.453691,97.188988 29.453691,82.564554 43.09497,82.564554 43.09497,77.75119 29.453691,77.75119 29.453691,63.126757 24.189714,63.126757 24.189714,70.438974 24.189714,77.75119 10.548436,77.75119 10.548436,82.564554 24.189714,82.564554 24.189714,97.188988 "
        />
        <polygon
          id="polygon8"
          fill="#1f447e"
          points="86.62007,97.188988 86.62007,82.339248 100.26135,82.339248 100.26135,77.75119 86.62007,77.75119 86.62007,63.126757 81.356093,63.126757 81.356093,70.438974 81.356093,77.75119 67.714814,77.75119 67.714814,82.339248 81.356093,82.339248 81.356093,97.188988 "
        />
        <polygon
          id="polygon9"
          fill="#5b6591"
          points="57.821815,108.55672 57.821815,98.295038 67.059378,98.295038 67.059378,94.13711 57.821815,94.13711 57.821815,83.875428 53.213275,83.875428 53.213275,89.01651 53.213275,94.13711 43.975713,94.13711 43.975713,98.295038 53.213275,98.295038 53.213275,108.55672 "
        />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'TableauIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
